<form [formGroup]="form" class="edit-section" style="padding-left: 15px;">
  <div class="row">
    <div class="form-field col-2">
      <div class="row">
        <label class="detail-field-title">Consignee</label>
      </div>
      <div class="row detail-field-content">
        <mat-form-field appearance="outline" class="extended-width">
          <mat-select formControlName="consigneeOrgCode" disableOptionCentering panelClass="fixed-select-panel" (openedChange)="selectInputFilter.setFocus($event)">
            <app-select-filterable #selectInputFilter [options]="consignees" (returnFilter)="consigneesFilter =$event" [displayLabel]="'name'"></app-select-filterable>
            <mat-option [value]="null">---</mat-option>
            <mat-option *ngFor="let org of consigneesFilter" [value]="org.code">
              {{org.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="form-field col-2">
      <div class="row">
        <label class="detail-field-title">Consignee Location</label>
      </div>
      <div class="row detail-field-content">
        <mat-form-field appearance="outline" class="extended-width">
          <mat-select formControlName="consigneeLocationCode" disableOptionCentering panelClass="fixed-select-panel" (openedChange)="selectInputFilter2nd.setFocus($event)">
            <app-select-filterable #selectInputFilter2nd [options]="consigneeLocations" (returnFilter)="consigneeLocationFilter =$event" [displayLabel]="'code'"></app-select-filterable>
            <mat-option [value]="null">---</mat-option>
            <mat-option *ngFor="let location of consigneeLocationFilter" [value]="location.code">
              {{ location | location : 'full' }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="form-field col-2">
      <div class="row">
        <label class="detail-field-title">Ship To Location</label>
      </div>
      <div class="row detail-field-content">
        <mat-form-field appearance="outline" class="extended-width">
          <mat-select formControlName="shipToLocationCode" disableOptionCentering panelClass="fixed-select-panel" (openedChange)="selectInputFilter3rd.setFocus($event)">
            <app-select-filterable #selectInputFilter3rd [options]="consigneeLocations" (returnFilter)="shipToLocationsFilter =$event" [displayLabel]="'code'"></app-select-filterable>
            <mat-option [value]="null">---</mat-option>
            <mat-option *ngFor="let location of shipToLocationsFilter" [value]="location.code">
              {{ location | location : 'full' }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="row">
<!--    <div class="form-field col-2">-->
<!--      <div class="row">-->
<!--        <label class="detail-field-title">Customer Location</label>-->
<!--      </div>-->
<!--      <div class="row detail-field-content">-->
<!--        <mat-form-field appearance="outline" class="extended-width">-->
<!--          <mat-select formControlName="customerLocationCode" disableOptionCentering panelClass="fixed-select-panel" (openedChange)="selectInputFilter4th.setFocus($event)">-->
<!--            <app-select-filterable #selectInputFilter4th [options]="customerLocations" (returnFilter)="customerLocationFilter =$event" [displayLabel]="'code'"></app-select-filterable>-->
<!--            <mat-option [value]="null">-&#45;&#45;</mat-option>-->
<!--            <mat-option *ngFor="let location of customerLocationFilter" [value]="location.code">{{ location | location : 'full' }}-->
<!--            </mat-option>-->
<!--          </mat-select>-->
<!--        </mat-form-field>-->
<!--      </div>-->
<!--    </div>-->
    <div class="form-field col-2">
      <div class="row">
        <label class="detail-field-title">Manufacturer</label>
      </div>
      <div class="row detail-field-content">
        <mat-form-field appearance="outline" class="extended-width">
          <mat-select formControlName="manufacturerOrgCode" disableOptionCentering panelClass="fixed-select-panel" (openedChange)="selectInputFilter5th.setFocus($event)">
            <app-select-filterable #selectInputFilter5th [options]="manufacturers" (returnFilter)="manufactureFilter =$event" [displayLabel]="'name'"></app-select-filterable>
            <mat-option [value]="null">---</mat-option>
            <mat-option *ngFor="let org of manufactureFilter" [value]="org.code">{{ org.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="form-field col-2">
      <div class="row">
        <label class="detail-field-title">Pickup Location</label>
      </div>
      <div class="row detail-field-content">
        <mat-form-field appearance="outline" class="extended-width">
          <mat-select formControlName="pickupLocationCode" disableOptionCentering panelClass="fixed-select-panel" (openedChange)="selectInputFilter6th.setFocus($event)">
            <app-select-filterable #selectInputFilter6th [options]="shipperLocations" (returnFilter)="pickupLocationsFilter =$event" [displayLabel]="'code'"></app-select-filterable>
            <mat-option [value]="null">---</mat-option>
            <mat-option *ngFor="let location of pickupLocationsFilter" [value]="location.code">{{ location | location : 'full' }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="form-field col-2">
      <div class="row">
        <label class="detail-field-title">Shipper Location</label>
      </div>
      <div class="row detail-field-content">
        <mat-form-field appearance="outline" class="extended-width">
          <mat-select formControlName="shipperLocationCode" disableOptionCentering panelClass="fixed-select-panel" (openedChange)="selectInputFilter7th.setFocus($event)">
            <app-select-filterable #selectInputFilter7th [options]="shipperLocations" (returnFilter)="shipperLocationsFilter =$event" [displayLabel]="'code'"></app-select-filterable>
            <mat-option [value]="null">---</mat-option>
            <mat-option *ngFor="let location of shipperLocationsFilter" [value]="location.code">{{ location | location : 'full' }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="form-field col-2">
      <div class="row">
        <app-unloco label="POL" formControlName="pol" [extendedWidth]="true" class="unloco-width"></app-unloco>
      </div>
    </div>
    <div class="form-field col-2">
      <div class="row">
        <app-unloco label="POD" formControlName="pod" [extendedWidth]="true" class="unloco-width"></app-unloco>
      </div>
    </div>
    <div class="form-field col-2">
      <div class="row">
        <label class="detail-field-title">Incoterm</label>
      </div>
      <div class="row detail-field-content">
        <mat-form-field appearance="outline" class="extended-width">
          <mat-select formControlName="incoterm" disableOptionCentering panelClass="fixed-select-panel" (openedChange)="selectInputFilter8th.setFocus($event)">
            <app-select-filterable #selectInputFilter8th [options]="incoterms" (returnFilter)="incotermFilter =$event" [displayLabel]="'fullText'"></app-select-filterable>
            <mat-option [value]="null">---</mat-option>
            <mat-option *ngFor="let option of incotermFilter" [value]="option.code">
              {{ option.code }} - {{ option.description }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="form-field col-2">
      <div class="row">
        <label class="detail-field-title">Forwarder</label>
      </div>
      <div class="row detail-field-content">
        <mat-form-field appearance="outline" class="extended-width">
          <mat-select formControlName="forwarder" disableOptionCentering panelClass="fixed-select-panel" (openedChange)="selectInputFilter9th.setFocus($event)">
            <app-select-filterable #selectInputFilter9th [options]="forwarders" (returnFilter)="forwardersFilter =$event" [displayLabel]="'name'"></app-select-filterable>
            <mat-option [value]="null">---</mat-option>
            <mat-option *ngFor="let org of forwardersFilter" [value]="org">
              {{org.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="form-field col-2">
      <div class="row">
        <label class="detail-field-title">Forwarder Contact</label>
      </div>
      <div class="row detail-field-content">
        <mat-form-field appearance="outline" class="extended-width">
          <mat-select matInput type="text" formControlName="forwarderContactId" disableOptionCentering panelClass="fixed-select-panel" (openedChange)="selectInputFilter10th.setFocus($event)" >
            <app-select-filterable #selectInputFilter10th [options]="forwarderContacts" (returnFilter)="forwarderContactsFilter =$event" [displayLabel]="'fullText'"></app-select-filterable>
            <mat-option *ngFor="let contact of forwarderContactsFilter" [value]="contact.id" class="multiline-mat-option">
              {{contact | contact:'full'}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
</form>
