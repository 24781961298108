import { BlobStorageService, FileStorageType } from './../../../core/services/blob-storage.service';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Type } from '../../constants';
import { DocumentService } from '@services/document.service';
import * as FileSaver from 'file-saver';
import {
  DocumentFile,
  DocumentSource,
  InvoiceFileStatus,
  SyncStatus,
  DocumentDownloadRequest,
  DocumentParent
} from '@models/document.model';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '@modules/auth/services/user.service';

@Component({
  selector: 'app-file-browser',
  styleUrls: ['./file-browser.component.scss'],
  template: `
    <app-om-card class="file-browser-wrapper">
      <app-om-card-content>
        <div class="doc-toolbar">
          <app-file-upload
            [uploadURL]="filesURL"
            [parent]="parent"
            [parentId]="parentId"
            [fileStorageType]="fileStorageType"
            [fileTypeOptions]="fileTypeOptions"
            (filesUploaded)="detectFileUpload()"
          ></app-file-upload>
          <button
            id="download-button"
            *ngIf="filesList.length !== 0"
            mat-raised-button
            type="button"
            class="btn btn-success download-btn"
            [disabled]="downloading || noDocumentsSelected"
            (click)="downloadSelected()"
          >
            <mat-icon>download</mat-icon>
            Download
          </button>
        </div>

        <app-om-table
          tableKey="file-browser-table"
          [dataSource]="filesList"
          [allowRowExpansion]="false"
          [whiteBackground]="true"
        >
          <app-om-table-custom-cells-wrapper>
            <ng-container matColumnDef="checkbox" sticky>
              <th mat-header-cell *matHeaderCellDef class="om-table-header external-header">
                <ng-container *ngIf="!noDocumentsCanDownload; else noAllCheckBox">
                  <mat-checkbox
                    (change)="selectAll()"
                    [ngModel]="selectAllChecked"
                    class="header-checkbox"
                    matTooltip="Select all for download"
                    matTooltipClass="largerTooltip"
                  ></mat-checkbox>
                </ng-container>
                <ng-template #noAllCheckBox>
                  <mat-checkbox
                    disabled
                    indeterminate
                    class="header-checkbox"
                    matTooltip="Download not available"
                    matTooltipClass="largerTooltip"
                  ></mat-checkbox>
                </ng-template>
              </th>
              <td mat-cell *matCellDef="let element" class="om-table-header external-header">
                <ng-container *ngIf="element.canDownload; else noCheckBox">
                  <mat-checkbox
                    [(ngModel)]="element.selected"
                    matTooltip="Select for download"
                    matTooltipClass="largerTooltip"
                  ></mat-checkbox>
                </ng-container>
                <ng-template #noCheckBox>
                  <mat-checkbox
                    disabled
                    indeterminate
                    matTooltip="Download not available"
                    matTooltipClass="largerTooltip"
                  ></mat-checkbox>
                </ng-template>
              </td>
            </ng-container>
            <ng-container matColumnDef="filename">
              <th mat-header-cell *matHeaderCellDef class="om-table-header external-header">File Name</th>
              <td mat-cell *matCellDef="let element">
                <div class="td-div">{{ element.fileName }}</div>
              </td>
            </ng-container>
            <ng-container matColumnDef="status">
              <th  mat-header-cell  *matHeaderCellDef class="om-table-header external-header" [ngClass]="parent === DocumentParent.Booking ? '' : 'display-none'">Status</th>
              <td mat-cell *matCellDef="let element" [ngClass]="parent === DocumentParent.Booking ? '' : 'display-none'">
                <div class="td-div" *ngIf="parent === DocumentParent.Booking">
                  {{ getDocumentSyncStatusDesc(element) }}
                  <mat-icon
                    *ngIf="isAdmin && element.syncStatus === syncStatusEnum.Failure"
                    [matTooltip]="element.errorLog"
                    matTooltipClass="largerTooltip"
                    class="warning-message"
                  >
                    info
                  </mat-icon>
                </div>
              </td>
            </ng-container>
            <ng-container class="center-content-vertically" matColumnDef="documentType">
              <th mat-header-cell *matHeaderCellDef class="om-table-header external-header">DOCUMENT NAME</th>
              <td mat-cell *matCellDef="let element">
                <div class="td-div">
                  <ng-container [ngSwitch]="getFileType(element.fileName)">
                    <mat-icon
                      *ngSwitchCase="'pdf'"
                      class="fa-lg mr-1"
                      style="color: #c40f12"
                      svgIcon="file-pdf"
                    ></mat-icon>
                    <mat-icon
                      *ngSwitchCase="'xls'"
                      class="fa-lg mr-1"
                      style="color: #107c41"
                      svgIcon="file-excel"
                    ></mat-icon>
                    <mat-icon
                      *ngSwitchCase="'xlsx'"
                      class="fa-lg mr-1"
                      style="color: #107c41"
                      svgIcon="file-excel"
                    ></mat-icon>
                    <mat-icon
                      *ngSwitchCase="'doc'"
                      class="fa-lg mr-1"
                      style="color: #2b7cd3"
                      svgIcon="file-word"
                    ></mat-icon>
                    <mat-icon
                      *ngSwitchCase="'docx'"
                      class="fa-lg mr-1"
                      style="color: #2b7cd3"
                      svgIcon="file-word"
                    ></mat-icon>
                    <mat-icon
                      *ngSwitchCase="'zip'"
                      class="fa-lg mr-1"
                      style="color: #8d811c"
                      svgIcon="file-zipper"
                    ></mat-icon>
                    <mat-icon
                      *ngSwitchCase="'txt'"
                      class="fa-lg mr-1"
                      style="color: #404040"
                      svgIcon="file-lines"
                    ></mat-icon>
                    <mat-icon *ngSwitchDefault class="fa-lg mr-1" style="color: #005add" svgIcon="file"></mat-icon>
                  </ng-container>
                  {{ element.documentType | cwDocument }}
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="source" stickyEnd>
              <th mat-header-cell *matHeaderCellDef class="om-table-header external-header"></th>
              <td mat-cell *matCellDef="let element">
                <div *ngIf="isSuper && parent === DocumentParent.Booking" class="td-div">
                  <button *ngIf="element.source < 2" mat-raised-button
                          type="button"
                          [disabled]="syncing"
                          (click)="syncDoc(element)"
                          class="btn btn-success">
                    <div class="icon-container" [class.download-spinner]="syncing">
                      Sync
                    </div>
                  </button>
                </div>
              </td>
            </ng-container>
          </app-om-table-custom-cells-wrapper>
        </app-om-table>
      </app-om-card-content>
    </app-om-card>
  `,
})
export class FileBrowserComponent implements OnChanges {
  @Input() filesURL: string = '';
  @Input() fileTypeOptions: Type[] = [];
  @Input() supplierId: number;
  @Input() cwNumber: string;
  @Input() parent: number;
  @Input() parentId: number;
  @Input() fileStorageType: number;
  @Input() fileNumber: string;

  public filesList: DocumentFile[] = [];
  public cwFilesList: any[] = [];
  public syncStatusEnum = SyncStatus;
  public downloading = false;
  public selectAllChecked = false;
  public isAdmin = false;
  public isSuper = false;
  public syncing = false

  public get noDocumentsSelected() {
    return !this.filesList.some(d => d.selected);
  }

  public get noDocumentsCanDownload() {
    return !this.filesList.some(d => d.canDownload);
  }

  constructor(
    private documentService: DocumentService,
    private blobStorageService: BlobStorageService,
    private toastr: ToastrService,
    private userService: UserService
  ) {
    this.isAdmin = this.getUserIsAdmin();
    this.isSuper = this.getUserIsSuper();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['parent'] &&
      changes['parent'].currentValue != '' &&
      changes['parentId'] &&
      changes['parentId'].currentValue != ''
    ) {
      this.fetchFiles();
    }
  }

  public async download(event: any, file: any) {
    event.stopImmediatePropagation();
    if (file != null) {
      file.downloading = true;
      const names = this.blobStorageService.getNamesByUrl(file.url);
      const sasToken = await this.blobStorageService.getSharedAccessSignature(file.fileStorageType, names.fileName);
      FileSaver.saveAs(`${file.url}?${sasToken}`, file.fileName);
      file.downloading = false;
    }
  }

  public detectFileUpload() {
    this.fetchFiles();
  }

  public syncDoc(element: DocumentFile): void {
    this.syncing = true
    this.documentService.syncFile(element).subscribe((res: any) => {
      this.syncing = false
      if(res.isSuccess) {
        this.toastr.success('Sync documents success.');
        element.syncStatus = SyncStatus.Success
      } else {
        this.toastr.error(res?.errorMessage || 'Something went wrong');
      }
    }, error =>{
      this.syncing = false
    })
  }

  public getFileType(name: string): string {
    const lookup = name.match(/\.\w*/g);
    if (lookup) {
      return lookup[lookup.length - 1]?.substring(1).toLowerCase();
    } else {
      return 'file';
    }
  }

  public fetchFiles() {
    this.documentService.getDocumentsList(this.parent, this.parentId).subscribe((filesList: DocumentFile[]) => {
      this.filesList = [...filesList];
    });
  }

  public addInvoiceFile(fileName: string, documentType: string, url: string) {
    const file: DocumentFile = {
      fileId: 0,
      fileName: fileName,
      userId: Number(this.userService.userValue.id),
      userName: this.userService.userValue.firstName + ' ' + this.userService.userValue.lastName,
      receivedDate: new Date().toString(),
      syncStatus: SyncStatus.InProgress,
      source: DocumentSource.InvoiceFile,
      errorLog: '',
      canDownload: false,
      cwDocumentId: null,
      invoiceFileStatus: InvoiceFileStatus.New,
      fileStorageType: FileStorageType.Invoice,
      documentType: documentType,
      url: url,
      dbPartition: 0,
      isSystemGenerated: false,
      selected: false,
    };

    this.filesList.unshift(file);
    this.filesList = [...this.filesList];
  }

  public getDocumentSyncStatusDesc(file: DocumentFile): string {
    let desc = '';
    if (file.source === DocumentSource.InvoiceFile && file.invoiceFileStatus === InvoiceFileStatus.New) {
      desc = 'Generating';
    } else {
      switch (file.syncStatus) {
        case SyncStatus.InProgress: {
          desc = 'In Progress';
          break;
        }
        case SyncStatus.Success: {
          desc = 'CargoWise Uploaded';
          break;
        }
        case SyncStatus.Failure: {
          desc = 'CargoWise Failed';
          break;
        }
        case SyncStatus.Done: {
          desc = 'Done';
          break;
        }
      }
    }
    return desc;
  }

  public selectAll(): void {
    this.selectAllChecked = !this.selectAllChecked;
    if (this.selectAllChecked) {
      this.filesList.forEach(document => {
        document.selected = true;
      });
    } else {
      this.filesList.forEach(document => (document.selected = false));
    }
  }

  public downloadSelected(): void {
    this.downloading = true;
    if (this.filesList.filter(document => document.selected).length > 0) {
      this.toastr.info('Document download in progress, please wait.', 'Starting download');
      const azureDownloadIds = [];
      const azureDoc = this.filesList.filter(document => document.selected && document.source !== DocumentSource.CargoWise);
      azureDoc.forEach(doc => {
        azureDownloadIds.push(doc.fileId);
      });

      let cwDocs = this.filesList.filter((document) => document.selected && document.source === DocumentSource.CargoWise);
      if (cwDocs.length > 0){
        const downloadsRequest = new DocumentDownloadRequest({
          documentList: [],
        });
        cwDocs.forEach((doc) => {
          downloadsRequest.documentList.push({ id: doc.cwDocumentId, DBPartition: doc.dbPartition, type: doc.documentType, name: doc.fileName });
        });

        this.documentService.getDocuments(downloadsRequest).subscribe(
          res => {
            FileSaver.saveAs(new Blob([res], { type: res.type }), this.fileNumber + ' Documents.zip');

            /**
             * If the doc does not exist in cargowise, get it from the azure blob
             */
            if (azureDownloadIds.length > 0) {
              this.downloadAzureFiles(azureDoc, azureDownloadIds);
            } else {
              this.toastr.success('Download Successfully');
              this.downloading = false;
              this.selectAllChecked = false;
              this.filesList.forEach(document => (document.selected = false));
            }
          },
          err => {
            this.downloading = false;
            this.toastr.error('Please try again later.', 'Error downloading documents.');
          }
        );
      }else{
        this.downloadAzureFiles(azureDoc, azureDownloadIds);
      }
    } else {
      this.downloading = false;
      this.toastr.warning('Please select the documents you would like to download.', 'No documents selected');
    }
  }

  private downloadAzureFiles(azureDoc, azureDownloadIds){

    if (azureDownloadIds.length > 0) {
      let azureBlobFinished = 0;
      this.blobStorageService.getFilesSAS(azureDownloadIds, true).subscribe(
        urlRes => {
          this.downloading = false;
          urlRes.forEach(item => {
            const currentDoc = azureDoc.filter(doc => doc.fileId === item.fileId)[0];
            FileSaver.saveAs(`${currentDoc.url}?${item.sas}`, currentDoc.fileName);
            azureBlobFinished++;
            if (azureBlobFinished === azureDownloadIds.length) {
              this.downloading = false;
              this.filesList.forEach(document => (document.selected = false));
              this.selectAllChecked = false;
              this.toastr.success('Download Successfully');
            }
          });
        },
        error => {
          this.downloading = false;
          this.toastr.error('Please try again later.', 'Error downloading documents.');
        }
      );
    }
  }

  public getUserIsAdmin(): boolean {
    if (
      this.userService.userValue.isOmsAdmin ||
      this.userService.currentOrg.isNetworkAdmin ||
      this.userService.currentOrg.isOrganizationAdmin
    ) {
      return true;
    }
  }

  public getUserIsSuper(): boolean {
    return this.userService.userValue.isOmsAdmin;
  }

  protected readonly DocumentParent = DocumentParent;
}
