import { TablePreferenceState } from './../../../state/om-table.state';
import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { LoadNewTablePreferences } from '../../../state/om-table.actions';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { CustomCellsWrapperComponent } from './custom-cells-wrapper.component';
import { ITableConfig } from '../../../state/om-table.state';
import { BaseFilter, FilterDelegate } from '@models/filter';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { switchMap, take, takeUntil } from 'rxjs/operators';
import { MatSort, Sort } from '@angular/material/sort';
import { IHyperlinkColumnConfig, ITableColumnConfig } from './table-configurations';
import { Router } from '@angular/router';
import { ExportDelegate, NestedTableExportConfig } from './om-table-export.component';
import { ExcelService } from '@services/excel.service';
import { MatPaginator } from '@angular/material/paginator';
import { BookingStatus, PurchaseOrderStatus } from '@models/helpers';
import { MatDialog } from '@angular/material/dialog';
import { RefsListDialogComponent } from '../refs-list-dialog/refs-list-dialog.component';
import { boolean, number } from 'mathjs';

@Component({
  selector: 'app-om-table',
  styleUrls: ['./om-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
    trigger('rotatedState', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(-180deg)' })),
      transition('rotated => default', animate('100ms ease-out')),
      transition('default => rotated', animate('200ms ease-in')),
    ]),
  ],
  templateUrl: './om-table.component.html',
})
export class OmTableComponent implements FilterDelegate, ExportDelegate, AfterContentInit, AfterViewInit, OnDestroy {
  @Input() tableKey: string = null;
  @Input() tableHeight: string;

  private _dataSource: any[] = [];
  @Input() public set dataSource(value: any[]) {
    this._dataSource = !value ? [] : value;
    this.filterData();
  }
  public get dataSource(): any[] {
    return this._dataSource;
  }

  private _matTableDataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  public get matTableDataSource(): MatTableDataSource<any> {
    return this._matTableDataSource;
  }

  private _tableLoading: boolean = false;
  @Input() public set tableLoading(value: any) {
    this._tableLoading = value;
  }
  public get tableLoading(): boolean {
    return this._tableLoading;
  }

  @Input() pageSize: number = 10;
  @Input() pageIndex: number = 1;
  @Input() dataLength: number = 0;
  @Input() hidePaginator: boolean = false;
  @Input() pageSizeOptions: number[] = [10, 20, 30];
  @Input() showTotalPages: number = 3;
  @Input() paginatorId: string = 'main';

  @Input() templateRef: TemplateRef<any>;
  @Input() nested: boolean = false;
  @Input() headerOnTop: boolean = false;
  @Input() grayScrollCorner: boolean = false;

  @Input() tableWidth: string;
  @Input() scrollLeft: number = 0;

  @Input() allowRowExpansion: boolean = false;
  @Input() allowMultipleRowExpansion: boolean = true;
  @Input() whiteBackground: boolean = false;
  @Input() childTable: boolean = false;
  @Input() equipmentTable: boolean = false;
  @Input() needPagination: boolean = false;
  @Input() hasExport: boolean = false;
  @Input() stickyToolBar: boolean = false;
  @Input() containerHeight: number;
  @Input() defaultExpanded: boolean = false;
  @Input() canSelect: boolean = false;
  @Input() childTableKey: string[] = [];
  @Input() hasRequestFilter: boolean = false;

  @Output() rowSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedElement: EventEmitter<{ event: any; element: any }> = new EventEmitter<{
    event: any;
    element: any;
  }>();
  @Output() sortChanged: EventEmitter<{ sortKey: string; direction: string }> = new EventEmitter<{
    sortKey: string;
    direction: string;
  }>();
  @Output() export: EventEmitter<any> = new EventEmitter<any>();
  @Output() pageChanged: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  @ViewChild(OmTableComponent, { static: true }) nestedTable: MatTable<any>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @ContentChild(CustomCellsWrapperComponent, { static: true }) customCells: CustomCellsWrapperComponent;

  public registeredFilters: { [key: string]: BaseFilter } = {};

  public tableConfig: ITableConfig;
  public filteredDataSource: any[] = [];
  public selectedItem = null;

  public bookingStatusEnum = BookingStatus;
  public poStatusEnum = PurchaseOrderStatus;

  public goToPage: number;
  public maxPage: number;
  public startNum: number;
  public endNum: number;

  private _ngUnsubscribe: Subject<void> = new Subject<void>();

  get tableData(): any[] {
    return this.filteredDataSource;
  }

  private _afterViewInit = false;
  private _InitalLoadData = true;

  constructor(
    private store: Store,
    private router: Router,
    private excelService: ExcelService,
    private dialog: MatDialog
  ) {}

  private setDataSource(): void {
    this._matTableDataSource = new MatTableDataSource<any>(this.filteredDataSource);
    this._matTableDataSource.sort = this.sort;
    this._matTableDataSource.paginator = this.paginator;
    this._matTableDataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'pickupLocation':
          return item[property].name;
        case 'shipToLocation':
          return item[property].name;
        case 'supplier':
          return item[property].name;
        case 'customer':
          return item[property].name;
        case 'buyingAgent':
          return item[property].name;
        case 'consignee':
          return item[property].name;
        case 'manufacturer':
          return item[property].name;
        case 'manufacturerLocation':
          return item[property].name;
        case 'shipper':
          return item[property].name;
        case 'shipperLocation':
          return item[property].name;
        case 'consigneeLocation':
          return item[property].name;
        default:
          return item[property];
      }
    };
  }

  ngAfterContentInit() {
    // CONFIGURE TABLE BEFORE VIEW INIT
    if (this.tableKey == undefined || this.tableKey == null) {
      console.error('Table load failed: No table key found.');
    } else {
      // LOAD TABLE STATE
      this.store
        .dispatch(new LoadNewTablePreferences(this.tableKey))
        .pipe(take(1))
        .subscribe(() => {
          this.store
            .selectOnce(state => state.tablePreferences[this.tableKey])
            .subscribe(res => {
              this.tableConfig = res;
              if (this.customCells) {
                this.customCells.columnDefs.forEach(cd => {
                  this.table.addColumnDef(cd);
                });
              }

              // SUBSCRIBE TO SUBSEQUENT CHANGES
              this.store
                .select(state => state.tablePreferences[this.tableKey])
                .pipe(takeUntil(this._ngUnsubscribe))
                .subscribe(res => {
                  this.tableConfig = res;
                });
            });
        });

      if (this.childTableKey.length > 0) {
        this.childTableKey.map(key => this.store.dispatch(new LoadNewTablePreferences(key)));
      }
    }
  }

  ngAfterViewInit(): void {
    // CONFIGURE DATA AFTER TABLE HAS BEEN CONFIGURED AND LOADED
    this._afterViewInit = true;
    this.filterData();
  }

  ngOnDestroy() {
    // SAVE TABLE STATE
    this._ngUnsubscribe.next();
    this._ngUnsubscribe.complete();
  }

  public toggleRow(rowElement: any): void {
    if (!this.allowRowExpansion) {
      console.warn('Action failed: Trying to expand row without having row expansion enabled.');
      return;
    }

    if (!this.allowMultipleRowExpansion && !rowElement.isExpanded) {
      this.filteredDataSource.forEach(d => (d.isExpanded = false));
    }

    rowElement.isExpanded = !rowElement.isExpanded;
  }

  public registerColumnDefs(customCells: CustomCellsWrapperComponent): void {
    customCells.columnDefs.forEach(cd => {
      this.table.addColumnDef(cd);
    });
  }

  public registerFilter(filterKey: string, filter: BaseFilter): void {
    this.registeredFilters[filterKey] = filter;
    this.filterData();
  }

  public unregisterFilter(filterKey: string): void {
    delete this.registeredFilters[filterKey];
    this.filterData();
  }

  public filterData(): void {
    let origData = this._dataSource.slice(0);
    if (this.hasRequestFilter) {
      const filters = Object.values(this.registeredFilters);
      filters.forEach(f => {
        origData = f.filterData(origData);
      });
    }
    this.filteredDataSource = origData;
    if (this.allowRowExpansion && this._InitalLoadData) {
      this.filteredDataSource.forEach(d => (d.isExpanded = this.defaultExpanded));
      this._InitalLoadData = false;
    }
    if (this._afterViewInit) this.setDataSource();
  }

  public handleSelect(element: any) {
    if(this.canSelect) {
      this.selectedItem = element
    }
    this.rowSelected.emit(element)
  }

  public onHyperLinkClick(event: any, item: any, config: IHyperlinkColumnConfig): void {
    event.stopImmediatePropagation();
    this.router.navigateByUrl(config.urlGrabber(item[config.primaryKeyAccessor]));
  }

  public onExternalHyperlinkClick(event: any, item: any, config: IHyperlinkColumnConfig): void {
    event.stopImmediatePropagation();
    window.open(config.urlGrabber(item[config.primaryKeyAccessor]), '_blank');
  }

  public exportAsExcel(datasetName: string, nestedConfig: NestedTableExportConfig): Observable<any> {
    if (this.hasExport) {
      let columnsConfigs = [
        {
          tableKey: this.tableKey,
          displayedColumns: this.getDisplayedColumnKeys(this.tableConfig),
        },
      ];
      if (nestedConfig.tableKey && nestedConfig.accessor) {
        return this.store
          .selectOnce(state => state.tablePreferences[nestedConfig.tableKey])
          .pipe(
            switchMap((nestedTableConfig: ITableConfig) => {
              let nestedColumnConfig = {
                tableKey: nestedConfig.tableKey,
                displayedColumns: this.getDisplayedColumnKeys(nestedTableConfig),
                isNested: true,
              };
              columnsConfigs.push(nestedColumnConfig);
              this.export.emit(columnsConfigs);
              return of();
            })
          );
      } else if (this.childTableKey.length > 0) {
        forkJoin(this.childTableKey.map(key => this.store.selectOnce(state => state.tablePreferences[key]))).subscribe(
          values => {
            values.forEach((item, index) => {
              if (item) {
                let nestedColumnConfig = {
                  tableKey: this.childTableKey[index],
                  displayedColumns: this.getDisplayedColumnKeys(item),
                  isNested: true,
                };

                columnsConfigs.push(nestedColumnConfig);
              }
            });
            this.export.emit(columnsConfigs);
            return of();
          }
        );
      } else {
        this.export.emit(columnsConfigs);
        return of();
      }
    } else {
      let cols = this.getDisplayedColumns(this.tableConfig);
      let data = this.filteredDataSource.map(d => {
        return Object.assign({}, d);
      });

      if (nestedConfig.tableKey && nestedConfig.accessor) {
        return this.store
          .selectOnce(state => state.tablePreferences[nestedConfig.tableKey])
          .pipe(
            switchMap((nestedTableConfig: ITableConfig) => {
              nestedConfig.columns = this.getDisplayedColumns(nestedTableConfig);
              return this.excelService.exportTable(cols, data, datasetName, nestedConfig);
            })
          );
      } else {
        return this.excelService.exportTable(cols, data, datasetName);
      }
    }
  }

  private getDisplayedColumnKeys(tableConfig: ITableConfig): string[] {
    let columns = this.getDisplayedColumns(tableConfig);
    return columns.map(i => i.key);
  }

  private getDisplayedColumns(tableConfig: ITableConfig): ITableColumnConfig[] {
    return tableConfig.renderColumns.filter(
      rc => rc.type !== 'custom' && tableConfig.displayColumns.some(dc => dc === rc.key)
    );
  }

  public refresh() {
    const tableCollections = document.getElementsByClassName('nested-table-container');

    for (let i = 0; i < tableCollections.length; i++) {
      (tableCollections[i] as HTMLElement).style.left = '0px';
    }
    //(document.querySelector('.nested-table-container') as HTMLElement).style.left = '0px';
  }

  public selectAllPoLines(event: any, element: any) {
    event.stopImmediatePropagation();
    this.selectedElement.emit({ event: event, element: element });
  }

  public sortChange(event: any) {
    const sortKey = this.tableConfig.renderColumns.find(c => c.key === event.active).sortKey;
    this.sortChanged.emit({ sortKey: sortKey ? sortKey : '', direction: event.direction });
  }

  public openRelatedDialog(event: any, element: any, column: ITableColumnConfig) {
    event.stopImmediatePropagation();
    const dialogRef = this.dialog.open(RefsListDialogComponent, {
      disableClose: true,
      width: column.relatedObjConfig.width ? column.relatedObjConfig.width : '300px',
      maxHeight: '95vh',
      data: {
        title: column.name,
        list: element[column.relatedObjConfig.elementKey],
        config: {
          url: column.hyperLinkConfig.urlGrabber,
          idKey: column.relatedObjConfig.idKey,
          textKey: column.relatedObjConfig.textKey,
        },
      },
    });

    dialogRef.afterClosed().subscribe(() => {});
  }

  protected readonly JSON = JSON;
}
