import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Organization } from '@models/organization.model';
import { UserNetworkApiService } from '@services/user-network-api.service';
import {ToastrService} from "ngx-toastr";


@Component({
  selector: 'app-routing-guide-delete',
  template: `
    <h2 mat-dialog-title>Delete Routing Guide</h2>
    <mat-dialog-content>
      <p class="respect-breaks">Are you sure you want to delete this routing guide?</p>
    </mat-dialog-content>
    <mat-dialog-actions align="end" style="margin-bottom: 0; padding-bottom: 0;">
      <button mat-raised-button color="primary" class="destructive mr-2" mat-dialog-close>Cancel</button>
      <button mat-raised-button color="primary" class="constructive" (click)="delete()" [disabled]="loading">
        <div class="icon-container" [class.download-spinner]="loading">Yes</div></button>
    </mat-dialog-actions>
  `,
  styles: ['']
})
export class RoutingGuideDeleteComponent implements OnInit {
  public organizations: Organization[] = [];
  public loading: boolean;
  public id: number | null = null

  constructor(
    private userNetworkService: UserNetworkApiService,
    private dialogRef: MatDialogRef<any>,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) private data: any) {}

  ngOnInit(): void {
    this.id = this.data.id
  }

  delete(){
    this.loading = true;
    this.userNetworkService.deleteRoutingGuide(this.id).subscribe(
      res => {
        this.loading = false;
        this.dialogRef.close(true)
      },
      _ => {
        this.loading = false;
        this.toastr.error('Error deleting routing guide.');
      }
    );
  }

}
