import { Component, OnInit } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { Router } from '@angular/router';
import { UserOrg } from '@models/organization.model';
import { UserService } from '@modules/auth/services/user.service';
import { skipWhile } from 'rxjs/operators';
import {PageOrgMode} from "@models/helpers";

@Component({
  selector: 'app-om-admin-org-selector',
  styleUrls: ['./admin-org-selector.component.scss'],
  template: `
    <div id="admin-org-selector" class="form-field" *ngIf="hasOrgSelector">
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Organization</mat-label>
        <mat-select
          disableOptionCentering panelClass="fixed-select-panel"
          [value]="selectedOrg"
          (selectionChange)="onOrgSelected($event)"
          [compareWith]="compareWith"
        >
          <mat-option *ngFor="let org of organizations" [value]="org">
            {{ org.name }} ({{ org.type }})
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  `,
})
export class AdminOrgSelectorComponent implements OnInit {
  public organizations: UserOrg[];
  public selectedOrg = null;
  public hasOrgSelector = true;
  constructor(private userService: UserService, private router: Router) {}

  public ngOnInit() {

    this.userService.user$
      .pipe(
        skipWhile((user) => {
          return !user || !user.id;
        })
      )
      .subscribe((user) => {
        let networkOrgs = user.orgs.filter(o => o.network.id == user.currentNetworkId);
        if (user.isOmsAdmin){
          networkOrgs = networkOrgs.filter(o => o.type === "Client" || o.type === "Supplier");
        }
        this.hasOrgSelector = user.isOmsAdmin || networkOrgs.length > 0;
        this.organizations = networkOrgs;
        this.selectedOrg = this.userService.currentOrg;
      });
  }

  public onOrgSelected(event: MatSelectChange) {
    this.userService.setCurrentOrg(event.value);
    const url = 'app/dashboard/home';
    // Force route reload after network selection change
    this.router.navigate([url]);
    // this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
    //   this.router.navigate([url]);
    // });
  }

  public compareWith(o1: UserOrg, o2: UserOrg): boolean {
    if (!o1 || !o2) return false;
    return o1.id === o2.id && o1.type === o2.type;
  }
}
