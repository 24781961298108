export class FilteredRequest {
  public pageNumber: number;
  public pageSize: number;
  public sort: string;
  public sortDesc: boolean;
  public requestType: string;
  public filters: Filter[];
  public filterTypes: string[];
  public checkFallOff: boolean;
  public fallOffDays: number;

  constructor(init?: Partial<FilteredRequest>) {
    Object.assign(this, init);
  }

  public prepForDispatch(dispatchType: string): void {
    this.filters.forEach(item => {
      item.condition = item.shortCode;
      if (item.dataField === 'weight') {
        this.filters.push(
          new Filter({
            dataField: 'details.weightUnit',
            condition: 'eq',
            value: item.value2,
            value2: null,
            selected: true,
          })
        );
      }
      if (item.dataField === 'volume') {
        this.filters.push(
          new Filter({
            dataField: 'details.volumeUnit',
            condition: 'eq',
            value: item.value2,
            value2: null,
            selected: true,
          })
        );
      }
      let filterField: FilterField;
      switch (dispatchType) {
        case 'container':
          filterField = new ContainerFilterField();
          break;
        case 'shipment':
          filterField = new ShipmentFilterField();
          break;
      }
      const field = filterField.fieldList.find(listItem => listItem.text === item.dataField);
      item.dataField = field ? field.dbColumn : item.dataField;
    });
  }
}

export class FilteredDownloadRequest {
  public offset: number;
  public limit: number;
  public sort: string;
  public sortDesc: boolean;
  public requestType: string;
  public filters: Filter[];
  public filterTypes: string[];
  public checkFallOff: boolean;
  public fallOffDays: number;

  constructor(init?: Partial<FilteredDownloadRequest>) {
    Object.assign(this, init);
  }

  public prepForDispatch(dispatchType: string): void {
    this.filters.forEach(item => {
      item.condition = item.shortCode;
      if (item.dataField === 'weight') {
        this.filters.push(
          new Filter({
            dataField: 'details.weightUnit',
            condition: 'eq',
            value: item.value2,
            value2: null,
            selected: true,
          })
        );
      }
      if (item.dataField === 'volume') {
        this.filters.push(
          new Filter({
            dataField: 'details.volumeUnit',
            condition: 'eq',
            value: item.value2,
            value2: null,
            selected: true,
          })
        );
      }
      let filterField: FilterField;
      switch (dispatchType) {
        case 'container':
          filterField = new ContainerFilterField();
          break;
        case 'shipment':
          filterField = new ShipmentFilterField();
          break;
      }
      const field = filterField.fieldList.find(listItem => listItem.text === item.dataField);
      item.dataField = field ? field.dbColumn : item.dataField;
    });
  }
}

export class Filter {
  private readonly boostOverrides = [''];

  public id?: number;
  public selected: boolean;
  public dataField: string;
  public condition: string;
  public dbField: string;
  public shortCode: string;
  public value: string;
  public value2: string;
  get boost(): number {
    if (this.boostOverrides.includes(this.dataField)) {
      return 2;
    } else {
      return 1;
    }
  }

  constructor(init?: any) {
    if (init.boost) delete init['boost'];
    Object.assign(this, init);
    if (!this.shortCode) {
      this.shortCode = this.getShortCode(this.condition);
    }
    if (this.condition === 'Starts with' && !this.value.endsWith('*')) {
      this.value = this.value + '*';
      // XYZ*
    }
    if (this.condition === 'Ends with' && !this.value.startsWith('*')) {
      this.value = '*' + this.value;
      // *XYZ
    }
    if (this.condition === 'Contains' && !this.value.startsWith('*')) {
      this.value = '*' + this.value;
    }
    if (this.condition === 'Contains' && !this.value.endsWith('*')) {
      this.value = this.value + '*';
    }
    // *XYZ*
    if (this.condition === 'Fuzzy' && !this.value.endsWith('~')) {
      this.value = this.value + '~';
      // XYZ~
    }
  }

  private getShortCode(condition: string): string {
    let massArray: FieldCondition[] = [];
    massArray = massArray.concat(FilterField.stringConditions);
    massArray = massArray.concat(FilterField.nonStringConditions);
    massArray = massArray.concat(FilterField.booleanConditions);
    return massArray.find(x => x.text === condition).shortCode;
  }

  public getDBField(type: string, dataField: string): string {
    let filterField: FilterField;
    switch (type) {
      case 'shipment':
        filterField = new ShipmentFilterField();
        break;
      default:
        return null;
    }
    return filterField.fieldList.find(item => item.text === dataField).dbColumn;
  }
}

export type ConditionType = 'text' | 'number' | 'date' | 'boolean';

/**
 * @param text Text visible to user in dropdown
 * @param dbColumn Exact (case-sensitive) match for elastic column name
 * @param conditionType Used to determine what conditions to display
 * @param conditions List of conditions
 * @param conditionUnits Any units related to the specified condition
 */
export class Field {
  public text: string;
  public dbColumn: string;
  public conditionType: ConditionType;
  public conditions?: FieldCondition[];
  public conditionUnits?: string[]; // kg || lbs || m3 || lightyear || smoot

  constructor(init?: Partial<Field>) {
    Object.assign(this, init);
  }
}

/**
 * @prop {string} text - Text to be displayed in the dropdown selector
 * @prop {string} shortCode - Short code used in elastic queries
 * @prop {boolean} input1value - Used to override dropdown with given value
 * @prop {boolean} input2 - Enables/disables second value field
 * @prop {boolean} enableCalculation - show the calculation option (needed for dates that can be null or eeeempty).
 */
export class FieldCondition {
  public text: string;
  public shortCode: string;
  public input1value?: string | boolean | number;
  public input1: boolean;
  public input2: boolean;
  public numberOnly?: boolean;
  public dateOnly?: boolean;
}

/**
 * @prop {string} stringConditions - Static property used to fill condition dropdown for string comparisons
 * @prop {string} nonStringConditions - Static property used to fill condition dropdown for numerical & date comparisons
 * @prop {boolean} booleanConditions - Static property used to fill condition dropdown for boolean comparisons
 */
export class FilterField {
  public static readonly stringConditions: FieldCondition[] = [
    {
      text: 'Is empty or null',
      shortCode: 'em',
      input1value: true,
      input1: true,
      input2: false,
    },
    {
      text: 'Is not empty or null',
      shortCode: 'nem',
      input1value: true,
      input1: true,
      input2: false,
    },
    {
      text: 'Contains',
      shortCode: 'cn',
      input1: true,
      input2: false,
    },
    {
      text: 'Does Not Contain',
      shortCode: 'ncn',
      input1: true,
      input2: false,
    },
    {
      text: 'Starts with',
      shortCode: 'qs',
      input1: true,
      input2: false,
    },
    {
      text: 'Ends with',
      shortCode: 'qs',
      input1: true,
      input2: false,
    },
    {
      text: 'Is exactly',
      shortCode: 'eq',
      input1: true,
      input2: false,
    },
    {
      text: 'Fuzzy',
      shortCode: 'qs',
      input1: true,
      input2: false,
    },
  ];

  public static readonly nonStringConditions: FieldCondition[] = [
    { text: 'Is equal to', shortCode: 'eq', input1: true, input2: false },
    { text: 'Is not equal to', shortCode: 'neq', input1: true, input2: false },
    { text: 'Is greater than', shortCode: 'gt', input1: true, input2: false },
    { text: 'Is greater than or equal to', shortCode: 'gte', input1: true, input2: false },
    { text: 'Is less than', shortCode: 'lt', input1: true, input2: false },
    { text: 'Is less than or equal to', shortCode: 'lte', input1: true, input2: false },
    { text: 'Is between', shortCode: 'bt', input1: true, input2: true },
    { text: 'Is not between', shortCode: 'nbt', input1: true, input2: true },
    { text: 'Is empty or null', shortCode: 'em', input1value: true, input1: false, input2: false },
    { text: 'Is not empty or null', shortCode: 'nem', input1value: true, input1: false, input2: false },
  ];

  public static readonly booleanConditions: FieldCondition[] = [
    { text: 'Is', shortCode: 'eq', input1: true, input2: false },
  ];

  public fieldList: Field[];

  public static formatDate(date: Date): string {
    let month = '' + (date.getMonth() + 1);
    let day = '' + date.getDate();
    const year = date.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    return [year, month, day].join('-');
  }
}

export class ContainerFilterField extends FilterField {
  public readonly fieldList: Field[] = [
    {
      text: 'Actual Delivery Date',
      dbColumn: 'actualDelivery',
      conditionType: 'date',
    },
    {
      text: 'Actual Full Delivery',
      dbColumn: 'actualDelivery',
      conditionType: 'date',
    },
    {
      text: 'ATA',
      dbColumn: 'actualTimeOfArrival', // @MyMo - DOES NOT EXIST
      conditionType: 'date',
    },
    {
      text: 'ETA',
      dbColumn: 'estimatedTimeOfArrival',
      conditionType: 'date',
    },
    {
      text: 'ETD',
      dbColumn: 'estimatedDeparture',
      conditionType: 'date',
    },
    {
      text: 'Consignee',
      dbColumn: 'addressing.consignee',
      conditionType: 'text',
    },
    {
      text: 'Container Number',
      dbColumn: 'containerNumber',
      conditionType: 'text',
    },
    {
      text: 'Container Type',
      dbColumn: 'equipment', // @MyMo - was containerTypeCode, DEPRECATED
      conditionType: 'text',
    },
    {
      text: 'Empty Return Date',
      dbColumn: 'returnInGate',
      conditionType: 'date',
    },
    {
      text: 'Equipment',
      dbColumn: 'equipment',
      conditionType: 'text',
    },
    {
      text: 'Status',
      dbColumn: 'consolidationStatus',
      conditionType: 'text',
    },
    {
      text: 'Estimated Delivery Date',
      dbColumn: 'estimatedTimeOfArrival',
      conditionType: 'date',
    },
    {
      text: 'Estimated Destination Date',
      dbColumn: 'estimatedTimeOfArrival',
      conditionType: 'date',
    },
    {
      text: 'Estimated Full Delivery',
      dbColumn: 'estimatedTimeOfArrival',
      conditionType: 'date',
    },
    {
      text: 'Onboard',
      dbColumn: 'onboard',
      conditionType: 'date',
    },
    {
      text: 'House Bill',
      dbColumn: 'shipmentLegs.vessel',
      conditionType: 'text',
    },
    {
      text: 'Vessel',
      dbColumn: 'consolidation.shipments.houseBill',
      conditionType: 'text',
    },
    {
      text: 'HTS',
      dbColumn: 'declarationDetails.invoiceLines.tariff',
      conditionType: 'text',
    },
    {
      text: 'Master Bill',
      dbColumn: 'consolidation.masterBill',
      conditionType: 'text',
    },
    {
      text: 'Mode',
      dbColumn: 'containerMode',
      conditionType: 'text',
    },
    {
      text: 'Onboard Date',
      dbColumn: 'onboard',
      conditionType: 'date',
    },
    {
      text: 'Order Refs',
      dbColumn: 'orderReferences',
      conditionType: 'text',
    },
    {
      text: 'Origin Actual Departure',
      dbColumn: 'shipmentLegs.atd', // @MyMo -- need to add a summation field
      conditionType: 'date',
    },
    {
      text: 'Origin Estimated Departure',
      dbColumn: 'shipmentLegs.etd',
      conditionType: 'date',
    },
    {
      text: 'Origin Gate In Date',
      dbColumn: 'gateIn',
      conditionType: 'date',
    },
    {
      text: 'Seal',
      dbColumn: 'sealNumber',
      conditionType: 'text',
    },
    {
      text: 'Shipment Number',
      dbColumn: 'consolidation.shipments.number',
      conditionType: 'text',
    },
    {
      text: 'Shipper',
      dbColumn: 'addressing.shipper',
      conditionType: 'text',
    },
    {
      text: 'Shippers Ref',
      dbColumn: 'shippersRef',
      conditionType: 'text', // @DR changed from date to text
    },
    {
      text: 'Supplier',
      dbColumn: 'addressing.consignor',
      conditionType: 'text',
    },
    {
      text: 'Volume',
      dbColumn: 'volume',
      conditionType: 'number',
      conditionUnits: ['m3'],
    },
    {
      text: 'Weight',
      dbColumn: 'weight',
      conditionType: 'number',
      conditionUnits: ['kg', 'lb'],
    },
    {
      text: 'Total Volume',
      dbColumn: 'volume',
      conditionType: 'number',
      conditionUnits: ['m3'],
    },
    {
      text: 'Total Weight',
      dbColumn: 'weight',
      conditionType: 'number',
      conditionUnits: ['kg', 'lb'],
    },
    {
      text: 'Total Packages',
      dbColumn: 'consolidation.shipments.packCount',
      conditionType: 'number',
    },
    {
      text: 'Origin/Dest',
      dbColumn: 'originPort.name',
      conditionType: 'text',
    },
    {
      text: 'Wharf Gate Out',
      dbColumn: 'gateOut',
      conditionType: 'date',
    },
  ]
    .map(i => {
      return {
        text: i.text,
        dbColumn: i.dbColumn,
        conditionType: i.conditionType,
      } as Field;
    })
    .filter(x => x.dbColumn !== '')
    .sort((a, b) => {
      if (a.text < b.text) {
        return -1;
      }
      if (a.text > b.text) {
        return 1;
      }
      return 0;
    });

  constructor() {
    super();
    this.fieldList.forEach(field => {
      switch (field.conditionType) {
        case 'date':
          field.conditions = FilterField.nonStringConditions.filter(fc => !fc.numberOnly);
          break;
        case 'number':
          field.conditions = FilterField.nonStringConditions.filter(fc => !fc.dateOnly);
          break;
        case 'boolean':
          field.conditions = FilterField.booleanConditions;
          break;
        case 'text':
        default:
          field.conditions = FilterField.stringConditions;
          break;
      }
    });
    this.fieldList = this.fieldList
      .filter(x => x.dbColumn !== '')
      .sort((a, b) => {
        if (a.text < b.text) {
          return -1;
        }
        if (a.text > b.text) {
          return 1;
        }
        return 0;
      });
  }
}

export class ShipmentFilterField extends FilterField {
  public readonly fieldList: Field[] = [
    {
      text: 'ATD',
      dbColumn: 'status.actualDeparture',
      conditionType: 'date',
    },
    {
      text: 'Actual Full Delivery',
      dbColumn: 'status.actualDelivery',
      conditionType: 'date',
    },
    {
      text: 'Consignee',
      dbColumn: 'addressing.consignee',
      conditionType: 'text',
    },
    {
      text: 'Container Number',
      dbColumn: 'consolidations.containers.number',
      conditionType: 'text',
    },
    {
      text: 'Customs Entry Number',
      dbColumn: 'declarationDetails.customsEntryNumber',
      conditionType: 'text',
    },
    {
      text: 'Destination Airport',
      dbColumn: 'details.destinationPort.code',
      conditionType: 'text',
    },
    {
      text: 'Destination City',
      dbColumn: 'addressing.deliveryAddress.city',
      conditionType: 'text',
    },
    {
      text: 'Destination Port Country Code',
      dbColumn: 'addressing.deliveryAddress.countryCode',
      conditionType: 'text',
    },
    {
      text: 'Destination State',
      dbColumn: 'addressing.deliveryAddress.state',
      conditionType: 'text',
    },
    {
      text: 'ETD',
      dbColumn: 'status.estimatedDeparture',
      conditionType: 'date',
    },
    {
      text: 'Estimated Arrival Date',
      dbColumn: 'status.estimatedArrival',
      conditionType: 'date',
    },
    {
      text: 'Estimated Delivery Date',
      dbColumn: 'status.estimatedDelivery',
      conditionType: 'date',
    },
    {
      text: 'House Bill',
      dbColumn: 'consolidations.shipments.houseBill',
      conditionType: 'text',
    },
    {
      text: 'HTS',
      dbColumn: 'declarationDetails.invoiceLines.tariff',
      conditionType: 'text',
    },
    {
      text: 'ITN Number',
      dbColumn: 'declarationDetails.itnNumber',
      conditionType: 'text',
    },
    {
      text: 'Master Bill',
      dbColumn: 'consolidations.masterBill',
      conditionType: 'text',
    },
    {
      text: 'Order Refs',
      dbColumn: 'orderReferences',
      conditionType: 'text',
    },
    {
      text: 'Origin City',
      dbColumn: 'addressing.consignorAddress.city',
      conditionType: 'text',
    },
    {
      text: 'Origin Port Country Code',
      dbColumn: 'addressing.consignorAddress.countryCode',
      conditionType: 'text',
    },
    {
      text: 'Origin State',
      dbColumn: 'addressing.consignorAddress.state',
      conditionType: 'text',
    },
    {
      text: 'Packs',
      dbColumn: 'details.totalPack',
      conditionType: 'number',
    },
    {
      text: 'Priority Shipment',
      dbColumn: 'status.isPriority',
      conditionType: 'boolean',
    },
    {
      text: 'Shipment Number',
      dbColumn: 'details.shipmentNumber',
      conditionType: 'text',
    },
    {
      text: 'Shipped Date',
      dbColumn: 'status.actualDeparture',
      conditionType: 'date',
    },
    {
      text: 'Shipper',
      dbColumn: 'addressing.shipper',
      conditionType: 'text',
    },
    {
      text: 'Shippers Ref',
      dbColumn: 'shippersRef',
      conditionType: 'text',
    },
    {
      text: 'Volume',
      dbColumn: 'details.totalVolume',
      conditionType: 'number',
      conditionUnits: ['cubic meters', 'cubic feet'],
    },
    {
      text: 'Weight',
      dbColumn: 'details.totalWeight',
      conditionType: 'number',
      conditionUnits: ['kg', 'lbs'],
    },
  ]
    .map(i => {
      return {
        text: i.text,
        dbColumn: i.dbColumn,
        conditionType: i.conditionType,
      } as Field;
    })
    .filter(x => x.dbColumn !== '')
    .sort((a, b) => {
      if (a.text < b.text) {
        return -1;
      }
      if (a.text > b.text) {
        return 1;
      }
      return 0;
    });

  constructor() {
    super();
    this.fieldList.forEach(field => {
      switch (field.conditionType) {
        case 'date':
          field.conditions = FilterField.nonStringConditions.filter(fc => !fc.numberOnly);
          break;
        case 'number':
          field.conditions = FilterField.nonStringConditions.filter(fc => !fc.dateOnly);
          break;
        case 'boolean':
          field.conditions = FilterField.booleanConditions;
          break;
        case 'text':
        default:
          field.conditions = FilterField.stringConditions;
          break;
      }
    });
    this.fieldList = this.fieldList
      .filter(x => x.dbColumn !== '')
      .sort((a, b) => {
        if (a.text < b.text) {
          return -1;
        }
        if (a.text > b.text) {
          return 1;
        }
        return 0;
      });
  }
}

export class ReportShipmentFilterField extends FilterField {
  public readonly fieldList: Field[] = [
    {
      text: 'ATD',
      dbColumn: 'status.actualDeparture',
      conditionType: 'date',
    },
    {
      text: 'Actual Full Delivery',
      dbColumn: 'status.actualDelivery',
      conditionType: 'date',
    },
    {
      text: 'Consignee',
      dbColumn: 'addressing.consignee',
      conditionType: 'text',
    },
    {
      text: 'Container Number',
      dbColumn: 'consolidations.containers.number',
      conditionType: 'text',
    },
    {
      text: 'Customs Entry Number',
      dbColumn: 'declarationDetails.customsEntryNumber',
      conditionType: 'text',
    },
    {
      text: 'Destination Airport',
      dbColumn: 'details.destinationPort.code',
      conditionType: 'text',
    },
    {
      text: 'Destination City',
      dbColumn: 'addressing.deliveryAddress.city',
      conditionType: 'text',
    },
    {
      text: 'Destination Port Country Code',
      dbColumn: 'addressing.deliveryAddress.countryCode',
      conditionType: 'text',
    },
    {
      text: 'Destination State',
      dbColumn: 'addressing.deliveryAddress.state',
      conditionType: 'text',
    },
    {
      text: 'ETD',
      dbColumn: 'status.estimatedDeparture',
      conditionType: 'date',
    },
    {
      text: 'Estimated Arrival Date',
      dbColumn: 'status.estimatedArrival',
      conditionType: 'date',
    },
    {
      text: 'Estimated Delivery Date',
      dbColumn: 'status.estimatedDelivery',
      conditionType: 'date',
    },
    {
      text: 'House Bill',
      dbColumn: 'consolidations.shipments.houseBill',
      conditionType: 'text',
    },
    {
      text: 'HTS',
      dbColumn: 'declarationDetails.invoiceLines.tariff',
      conditionType: 'text',
    },
    {
      text: 'ITN Number',
      dbColumn: 'declarationDetails.itnNumber',
      conditionType: 'text',
    },
    {
      text: 'Master Bill',
      dbColumn: 'consolidations.masterBill',
      conditionType: 'text',
    },
    {
      text: 'Order Refs',
      dbColumn: 'orderReferences',
      conditionType: 'text',
    },
    {
      text: 'Origin City',
      dbColumn: 'addressing.consignorAddress.city',
      conditionType: 'text',
    },
    {
      text: 'Origin Port Country Code',
      dbColumn: 'addressing.consignorAddress.countryCode',
      conditionType: 'text',
    },
    {
      text: 'Origin State',
      dbColumn: 'addressing.consignorAddress.state',
      conditionType: 'text',
    },
    {
      text: 'Packs',
      dbColumn: 'details.totalPack',
      conditionType: 'number',
    },
    {
      text: 'Priority Shipment',
      dbColumn: 'status.isPriority',
      conditionType: 'boolean',
    },
    {
      text: 'Shipment Number',
      dbColumn: 'details.shipmentNumber',
      conditionType: 'text',
    },
    {
      text: 'Shipped Date',
      dbColumn: 'status.actualDeparture',
      conditionType: 'date',
    },
    {
      text: 'Shipper',
      dbColumn: 'addressing.shipper',
      conditionType: 'text',
    },
    {
      text: 'Shippers Ref',
      dbColumn: 'shippersRef',
      conditionType: 'text',
    },
    {
      text: 'Volume',
      dbColumn: 'details.totalVolume',
      conditionType: 'number',
      conditionUnits: ['cubic meters', 'cubic feet'],
    },
    {
      text: 'Weight',
      dbColumn: 'details.totalWeight',
      conditionType: 'number',
      conditionUnits: ['kg', 'lbs'],
    },
  ]
    .map(i => {
      return {
        text: i.text,
        dbColumn: i.dbColumn,
        conditionType: i.conditionType,
      } as Field;
    })
    .filter(x => x.dbColumn !== '')
    .sort((a, b) => {
      if (a.text < b.text) {
        return -1;
      }
      if (a.text > b.text) {
        return 1;
      }
      return 0;
    });

  constructor() {
    super();
    this.fieldList.forEach(field => {
      switch (field.conditionType) {
        case 'date':
          field.conditions = FilterField.nonStringConditions.filter(fc => !fc.numberOnly);
          break;
        case 'number':
          field.conditions = FilterField.nonStringConditions.filter(fc => !fc.dateOnly);
          break;
        case 'boolean':
          field.conditions = FilterField.booleanConditions;
          break;
        case 'text':
        default:
          field.conditions = FilterField.stringConditions;
          break;
      }
    });
    this.fieldList = this.fieldList
      .filter(x => x.dbColumn !== '')
      .sort((a, b) => {
        if (a.text < b.text) {
          return -1;
        }
        if (a.text > b.text) {
          return 1;
        }
        return 0;
      });
  }
}

export class SeaShipmentFilterField extends FilterField {
  public readonly fieldList: Field[] = [
    {
      text: 'Order Refs',
      dbColumn: 'orderReferences', //
      conditionType: 'text',
    },
    {
      text: 'Priority',
      dbColumn: 'status.isPriority', //
      conditionType: 'boolean',
    },
    {
      text: 'Status',
      dbColumn: 'status.currentFriendly',
      conditionType: 'text',
    },
    {
      text: 'ETD',
      dbColumn: 'status.estimatedDeparture',
      conditionType: 'date',
    },
    {
      text: 'ETA',
      dbColumn: 'status.estimatedArrival',
      conditionType: 'date',
    },
    {
      text: 'Origin/Dest',
      dbColumn: 'addressing.consignorAddress.city',
      conditionType: 'text',
    },
    {
      text: 'Consignee',
      dbColumn: 'addressing.consignee',
      conditionType: 'text',
    },
    {
      text: 'Master Bill',
      dbColumn: 'consolidations.masterBill',
      conditionType: 'text',
    },
    {
      text: 'Container Number',
      dbColumn: 'consolidations.containers.number',
      conditionType: 'text',
    },
    {
      text: 'Origin ETD',
      dbColumn: 'shipmentLegs.etd',
      conditionType: 'date',
    },
    {
      text: 'Origin ATD',
      dbColumn: 'shipmentLegs.atd',
      conditionType: 'date',
    },
    {
      text: 'Shipper',
      dbColumn: 'addressing.shipper',
      conditionType: 'text',
    },
    {
      text: 'Shipment Number',
      dbColumn: 'details.shipmentNumber',
      conditionType: 'text',
    },
    {
      text: 'House Bill',
      dbColumn: 'consolidations.shipments.houseBill',
      conditionType: 'text',
    },
    {
      text: 'Shippers Ref',
      dbColumn: 'shippersRef',
      conditionType: 'text',
    },
    {
      text: 'Total Volume',
      dbColumn: 'details.totalVolume',
      conditionType: 'number',
      conditionUnits: ['cubic meters', 'cubic feet'],
    },
    {
      text: 'Total Weight',
      dbColumn: 'details.totalWeight',
      conditionType: 'number',
      conditionUnits: ['kg', 'lbs'],
    },
    {
      text: 'Total Packages',
      dbColumn: 'details.totalPack',
      conditionType: 'number',
    },
    {
      text: 'Estimated Full Delivery',
      dbColumn: 'status.estimatedDelivery',
      conditionType: 'date',
    },
    {
      text: 'Actual Full Delivery',
      dbColumn: 'status.actualDelivery',
      conditionType: 'date',
    },
    {
      text: 'Chargeable',
      dbColumn: 'details.chargeable',
      conditionType: 'number',
      conditionUnits: ['kg', 'lbs'],
    },
    {
      text: 'Vessel',
      dbColumn: 'shipmentLegs.vessel',
      conditionType: 'text',
    },
    {
      text: 'Master Air Waybill',
      dbColumn: 'consolidations.masterBill',
      conditionType: 'text',
    },
  ]
    .map(i => {
      return {
        text: i.text,
        dbColumn: i.dbColumn,
        conditionType: i.conditionType,
      } as Field;
    })
    .filter(x => x.dbColumn !== '')
    .sort((a, b) => {
      if (a.text < b.text) {
        return -1;
      }
      if (a.text > b.text) {
        return 1;
      }
      return 0;
    });

  constructor() {
    super();
    this.fieldList.forEach(field => {
      switch (field.conditionType) {
        case 'date':
          field.conditions = FilterField.nonStringConditions.filter(fc => !fc.numberOnly);
          break;
        case 'number':
          field.conditions = FilterField.nonStringConditions.filter(fc => !fc.dateOnly);
          break;
        case 'boolean':
          field.conditions = FilterField.booleanConditions;
          break;
        case 'text':
        default:
          field.conditions = FilterField.stringConditions;
          break;
      }
    });
    this.fieldList = this.fieldList
      .filter(x => x.dbColumn !== '')
      .sort((a, b) => {
        if (a.text < b.text) {
          return -1;
        }
        if (a.text > b.text) {
          return 1;
        }
        return 0;
      });
  }
}
