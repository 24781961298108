import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppUserCreate } from '@models/appUserCreate.model';
import { Contact } from '@models/contact.model';
import { RoutingGuide } from '@models/routing-guide.model';
import { INetworkBusinessRuleSet, ISupplierBusinessRuleSet } from '@models/supplier-business-rule-set.model';
import { Location } from '@models/location.model';
import { NetworkUser } from '@models/networkUser.model';
import { User } from '@modules/auth/models/user.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class UserNetworkApiService {
  constructor(private httpClient: HttpClient) {}

  public getUsers(id: number): Observable<any> {
    return this.httpClient.get<any>(`${environment.apiBaseURL}User/network/${id}`);
  }

  public updateUser(user: NetworkUser): Observable<any> {
    return this.httpClient.post<any>(`${environment.apiBaseURL}User/update`, user);
  }

  public createUser(user: AppUserCreate): Observable<AppUserCreate> {
    return this.httpClient.post<AppUserCreate>(`${environment.apiBaseURL}User/create`, user);
  }

  public updateUserEnabled(id: number, networkId: number, enabled: Boolean): Observable<any> {
    let url = `${environment.apiBaseURL}User/${id}/updateUserEnabled?enabled=${enabled}`;
    if (networkId) {
      url = `${url}&networkId=${networkId}`;
    }
    return this.httpClient.get<any>(url);
  }

  public getNetwork(networkID: number): Observable<any> {
    return this.httpClient.get<any>(`${environment.apiBaseURL}Organization/network/${networkID}`);
  }

  public getFullNetwork(): Observable<any> {
    return this.httpClient.get<any>(`${environment.apiBaseURL}Organization/fullNetwork`);
  }

  public getOrganizationsInNetwork(networkID: number, accessible: boolean): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.apiBaseURL}Organization/network/${networkID}/orgs?accessible=${accessible}`
    );
  }

  public getOrgsInCurrentNetwork(): Observable<any> {
    return this.httpClient.get<any>(`${environment.apiBaseURL}Organization/network/user`);
  }

  public getContactsInCurrentNetwork(): Observable<Contact[]> {
    return this.httpClient.get<Contact[]>(`${environment.apiBaseURL}Organization/networkContacts`);
  }

  public getClearFreightAdmins(): Observable<any> {
    return this.httpClient.get<any>(`${environment.apiBaseURL}User/admin`);
  }

  public updateClearFreightAdmins(user: NetworkUser): Observable<any> {
    return this.httpClient.post<any>(`${environment.apiBaseURL}User/admin/${user.id}`, user);
  }

  public getAllNetworks(): Observable<any> {
    return this.httpClient.get<any>(`${environment.apiBaseURL}Organization/network`);
  }

  public updateNetworkEnabled(networkId: Number, enabled: Boolean): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.apiBaseURL}Organization/network/${networkId}/update-enabled?enabled=${enabled}`
    );
  }

  public getAllUserNetworks(user: User): Observable<any[]> {
    return this.httpClient.get<any>(`${environment.apiBaseURL}User/${user.id}/customer`);
  }

  public getCWLocationsForOrg(orgCode: string): Observable<Location[]> {
    return this.httpClient.get<Location[]>(`${environment.apiBaseURL}Location/GetLocationsForOrg/${orgCode}`);
  }

  public getBusinessRules(supplierId: number): Observable<ISupplierBusinessRuleSet> {
    return this.httpClient.get<ISupplierBusinessRuleSet>(
      `${environment.apiBaseURL}Organization/network/supplier-business-rules?supplierId=${supplierId}`
    );
  }

  public saveBusinessRules(supplierId: number, rules: ISupplierBusinessRuleSet): Observable<ISupplierBusinessRuleSet> {
    return this.httpClient.post<ISupplierBusinessRuleSet>(
      `${environment.apiBaseURL}Organization/network/supplier-business-rules?supplierId=${supplierId}`,
      rules
    );
  }
  public getNetworkBusinessRules(): Observable<INetworkBusinessRuleSet> {
    return this.httpClient.get<INetworkBusinessRuleSet>(`${environment.apiBaseURL}Organization/network/business-rules`);
  }

  public saveNetworkBusinessRules(rules: INetworkBusinessRuleSet): Observable<INetworkBusinessRuleSet> {
    return this.httpClient.post<INetworkBusinessRuleSet>(
      `${environment.apiBaseURL}Organization/network/business-rules`,
      rules
    );
  }

  public getRoutingGuides(excludeDisabledForwarder: boolean): Observable<RoutingGuide[]> {
    let url = `${environment.apiBaseURL}Organization/network/routing-guides?excludeDisabledForwarder=${excludeDisabledForwarder}`;
    return this.httpClient.get<RoutingGuide[]>(url);
  }

  public saveRoutingGuide(guide: RoutingGuide): Observable<RoutingGuide> {
    return this.httpClient.post<RoutingGuide>(`${environment.apiBaseURL}Organization/network/routing-guide`, guide);
  }

  public deleteRoutingGuide(guideId: number): Observable<any> {
    return this.httpClient.delete<RoutingGuide[]>(
      `${environment.apiBaseURL}Organization/network/routing-guide/${guideId}`
    );
  }
}
