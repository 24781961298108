import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { Remark } from '@models/remark.model';
import { RemarkApiService, RemarkType } from '@services/remark-api.service';
import * as moment from 'moment';
@Component({
  selector: 'app-om-remarks',
  templateUrl: './remarks.component.html',
  styleUrls: ['./remarks.component.scss'],
})
export class RemarksComponent implements OnInit, OnDestroy {
  @Input() remarkType: RemarkType;
  @Input() parentId: number;
  @Input() disable: boolean = false;
  @Input() minHeight: string;
  remarks: Remark[] = [];
  private timer = null;
  text = '';

  constructor(private remarkService: RemarkApiService) {}

  ngOnDestroy(): void {
    clearInterval(this.timer);
  }

  ngOnInit(): void {
    clearInterval(this.timer);
    this.remarkService.getMarkerSetting().subscribe(res => {
      if(!res.disabled) {
        this.timer = setInterval(() => {
          this.getMarkers()
        }, res.refreshSeconds * 1000)
      }
    })
    this.getMarkers()
  }

  getMarkers() {
    this.remarkService.getRemarks(this.remarkType, this.parentId).subscribe(remarks => {
      this.remarks = remarks;
    });
  }

  public addRemark(text: string): void {
    this.remarkService.addRemark(this.remarkType, this.parentId, text).subscribe(remarks => {
      this.remarks = remarks;
    });
    this.text = '';
  }
}
