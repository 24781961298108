import { Component, OnInit } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { Router } from '@angular/router';
import { Network } from '@models/organization.model';
import { PageOrgMode } from '@models/helpers';
import { UserService } from '@modules/auth/services/user.service';
import { skipWhile, take } from 'rxjs/operators';

@Component({
    selector: 'app-om-global-network-selector',
    styleUrls: ['./global-network-selector.component.scss'],
    template: `
    <div id="global-network-selector" class="form-field">
        <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Network</mat-label>
            <mat-select disableOptionCentering panelClass="fixed-select-panel" [value]="selectedNetwork" (selectionChange)="onNetworkSelected($event)" [compareWith]="compareWith">
                <mat-option *ngFor="let network of omsNetworks" [value]="network">
                    {{network.customerName}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    `
})
export class GlobalNetworkSelectorComponent implements OnInit {
    public omsNetworks: Network[];
    public selectedNetwork = null;
    private mode: PageOrgMode;

    constructor(
      private userService: UserService,
      private router: Router) {}

    public ngOnInit() {
      this.userService.user$
        .pipe(
          skipWhile((user) => {
            return (!user || !user.id);
          }),
          take(1)
        )
        .subscribe(user => {
          this.omsNetworks = [];
          user.orgs.forEach(org => {
            if (!this.omsNetworks.some(n => n.id == org.network.id)) {
              this.omsNetworks.push(org.network);
            }
          });
          if (user.currentNetworkId == null) {
            this.selectedNetwork = user.orgs[0].network;
          } else {
            this.selectedNetwork = user.orgs.find(o => o.network.id === user.currentNetworkId).network;
          }
          this.userService.setUserNetwork(this.selectedNetwork);
        }
      );

      this.userService.user$.subscribe(user => {
        if (user.currentNetworkId && this.selectedNetwork && this.selectedNetwork.id !== user.currentNetworkId) {
          this.selectedNetwork = user.orgs.find(o => o.network.id === user.currentNetworkId).network;
        }
        if (Object.keys(user).length) {
          this.mode = this.userService.orgMode;
        }
      });
    }

    public onNetworkSelected(event: MatSelectChange) {
      this.userService.setUserNetwork(event.value);
      const redirectUrl = 'app/dashboard/home';

      // Force route reload after network selection change
      this.router.navigate([redirectUrl]);
    }

    public compareWith(n1: Network, n2: Network): boolean {
      if (!n1 || !n2) return false;
      return n1.id === n2.id;
    }
}
