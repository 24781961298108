<div class="table-header-tool" *ngIf="headerAttr">
  <button mat-button [matMenuTriggerFor]="menu" style="padding: 0; min-width: 0" (click)="handleClickButton($event)" >
    <mat-icon style="font-size: 16px;" >more_vert</mat-icon>
    <div *ngIf="curFilter.length > 0" class="table-header-badge" >{{curFilter.length}}</div>
  </button>
  <mat-menu #menu="matMenu" class="table-header-menu">
    <div (click) = "$event.stopPropagation()" >
      <div [formGroup]="filterForm" class="d-flex w-auto flex-column align-baseline justify-content-center pl-3 pr-3 pb-0 pt-3">
        <mat-form-field appearance="outline" class="small-form-field">
          <mat-label>Condition</mat-label>
          <mat-select (change)="$event.stopPropagation(); $event.preventDefault()"  (openedChange)="selectInputFilter2nd.setFocus($event)" disableOptionCentering panelClass="fixed-select-panel small-size" formControlName="condition" (selectionChange)="conditionChanged($event)">
            <app-select-filterable #selectInputFilter2nd (returnFilter)="filterCondition = $event" [options]="conditions" [displayLabel]="'text'"></app-select-filterable>
            <mat-option *ngFor="let condition of filterCondition" [value]="condition">{{ condition?.text }} </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="small-form-field">
          <mat-label>Value{{ selectedCondition?.input2 ? " 1" : "" }}</mat-label>
          <mat-select *ngIf="headerAttr?.conditionType === 'boolean' || selectedCondition?.input1value === true"  formControlName="value" disableOptionCentering panelClass="fixed-select-panel small-size">
            <mat-option value="True">True</mat-option>
            <mat-option value="False">False</mat-option>
          </mat-select>
          <input
            *ngIf="headerAttr?.conditionType !== 'boolean' && selectedCondition?.input1value && selectedCondition?.input1value !== true"
            matInput
            formControlName="value"
            [type]="headerAttr?.conditionType"
          />
          <input
            *ngIf="headerAttr?.conditionType !== 'boolean' && !selectedCondition?.input1value"
            matInput
            [pattern]="headerAttr?.conditionType === 'number' ? '[0-9]+' : ''"
            formControlName="value"
            [type]="headerAttr?.conditionType"
          />
        </mat-form-field>
        <mat-form-field appearance="outline" [hidden]="!selectedCondition?.input2 && !headerAttr?.conditionUnits" class="small-form-field">
          <mat-label>Value 2</mat-label>
          <mat-select *ngIf="headerAttr?.conditionUnits" formControlName="value2" disableOptionCentering panelClass="fixed-select-panel small-size">
            <mat-option *ngFor="let unit of headerAttr.conditionUnits" [value]="unit">{{ unit }}</mat-option>
          </mat-select>
          <input
            *ngIf="!headerAttr?.conditionUnits && selectedCondition?.text === 'Days Range'"
            matInput
            placeholder="Days Range; EX: +20 OR -20"
            pattern="(\+|-){1}(\d){1,3}"
            maxlength="4"
            formControlName="value2"
            [type]="'text'"
          />
          <input
            *ngIf="!headerAttr?.conditionUnits && selectedCondition?.text !== 'Days Range'"
            matInput
            [pattern]="headerAttr?.conditionType === 'number' ? '[0-9]+' : ''"
            formControlName="value2"
            [type]="headerAttr?.conditionType"
          />
        </mat-form-field>
      </div>
      <div *ngIf="curFilter.length > 0" class="d-flex pl-3 pr-3 pt-0 pb-0 flex-wrap select-part" >
        <div *ngFor="let filter of curFilter; index as i" class="d-flex filter-tag" (click)="removeFilter(filter, i)">
          <mat-icon style="font-size: 12px; position: absolute; right: 2px; top: 2px; color: #808080; height: 12px; width: 12px">close</mat-icon>
          <span class="mr-1">{{filter.condition}}</span>
          <span>{{ filter.value.replace("*", "").replace("*", "").replace("~", "")
            }}{{ filter.value2 ? "\n" + filter.value2.replace("*", "").replace("*", "").replace("~", "") : "" }}</span>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-between pl-3 pr-3 mt-2">
      <button mat-button class="btn-small green-btn" (click)="applyFilter()">Apply</button>
      <button mat-button class="btn-small" (click)="cancelFilter()">Cancel</button>
    </div>
  </mat-menu>
</div>
