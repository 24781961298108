import { AfterViewInit, Component, OnInit } from '@angular/core';
import { UserNetworkApiService } from '@services/user-network-api.service';
import { MatDialog } from '@angular/material/dialog';
import { RoutingGuideEditorComponent } from './routing-guide-editor/routing-guide-editor.component';
import { RoutingGuide } from '@models/routing-guide.model';
import { ToastrService } from 'ngx-toastr';
import {UserEditComponent} from "@modules/user/components/user-edit/user-edit.component";
import {
  RoutingGuideDeleteComponent
} from "@modules/customer-network/views/routing-guide-admin/routing-guide-delete/routing-guide-delete.component";

@Component({
  selector: 'app-om-routing-guide-admin',
  styleUrls: [],
  templateUrl: './routing-guide-admin.component.html',
})
export class RoutingGuideAdminComponent implements OnInit, AfterViewInit {
  public routingGuides: RoutingGuide[] = [];

  public loading: boolean = false;
  public viewHeight = 0;

  constructor(
    private userNetworkService: UserNetworkApiService,
    private dialog: MatDialog,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.fetchRoutingGuides();
  }

  ngAfterViewInit(): void {
    this.viewHeight = window.innerHeight;
  }

  openEditor(event: any, element: any = null) {
    // event.stopImmediatePropagation();

    const dialogRef = this.dialog.open(RoutingGuideEditorComponent, {
      disableClose: true,
      width: '50%',
      data: {
        routingGuide: element,
      },
    });

    dialogRef.afterClosed().subscribe(success => {
      if (success) {
        this.fetchRoutingGuides();
      }
    });
  }

  deleteRoutingGuide(event: any, id: number) {
    // event.stopImmediatePropagation();
    const dialogRef = this.dialog.open(RoutingGuideDeleteComponent, {
      width: '450px',
      data: {
        id: id
      },
    });

    dialogRef.afterClosed().subscribe((needRefresh: false) => {
      if (needRefresh) {
        this.fetchRoutingGuides();
      }
    }, ()=> {
      this.toastr.error('Error deleting routing guide.');
    })

    // this.loading = true;
    // this.userNetworkService.deleteRoutingGuide(id).subscribe(
    //   res => {
    //     this.fetchRoutingGuides();
    //   },
    //   _ => {
    //     this.loading = false;
    //     this.toastr.error('Error deleting routing guide.');
    //   }
    // );
  }

  private fetchRoutingGuides(): void {
    this.loading = true;
    this.userNetworkService.getRoutingGuides(false).subscribe(
      routingGuides => {
        this.routingGuides = routingGuides;
        this.loading = false;
      },
      _ => {
        this.loading = false;
        this.toastr.error('Error retrieving routing guides.');
      }
    );
  }
}
