import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Remark, RemarkTimer} from '@models/remark.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export enum RemarkType {
  None = 0,
  PurchaseOrder = 1,
  Booking = 2,
}

@Injectable()
export class RemarkApiService {
  constructor(private httpClient: HttpClient) { }

  public getRemarks(type: RemarkType, parentId: number): Observable<Remark[]> {
    return this.httpClient.get<Remark[]>(`${environment.apiBaseURL}remark/${type}/${parentId}`).pipe(map(rs => rs.map(r => new Remark(r))));
  }

  public getMarkerSetting(): Observable<RemarkTimer> {
    return this.httpClient.get<RemarkTimer>(`${environment.apiBaseURL}user/getCommunicationConfig`);
  }

  public addRemark(type: RemarkType, parentId: number, text: string): Observable<Remark[]> {
    return this.httpClient.post<Remark[]>(`${environment.apiBaseURL}remark/${type}/${parentId}`, {text}).pipe(map(rs => rs.map(r => new Remark(r))));
  }
}
