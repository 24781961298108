import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { UserNetworkApiService } from '@services/user-network-api.service';
import { Organization, UserOrg } from '@models/organization.model';
import { Constants } from 'src/app/shared/constants';
import {
  IApiAccessKey,
  IAsnTriggerDefinition,
  ISupplierBusinessRuleSet,
} from '@models/supplier-business-rule-set.model';
import { Contact } from '@models/contact.model';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { UserService } from '@modules/auth/services/user.service';
import { Address4 } from 'ip-address';
import { PageOrgMode } from '@models/helpers';
import {ContactPipe} from "../../../../shared/pipes/contact.pipe";
import {OmCacheService} from "@services/om-cache.service";

@Component({
  selector: 'app-om-business-rules',
  styleUrls: ['./business-rules.component.scss'],
  templateUrl: './business-rules.component.html',
})
export class BusinessRulesComponent implements OnInit {
  documentTypes = Constants.documents;
  selectedSupplier: Organization;
  manufacturers: Organization[];
  consignees: Organization[];
  suppliers: Organization[];
  forwarders: Organization[];
  contacts: Contact[];
  customer: Organization;

  supplierFormInitialValues: any;
  networkFormInitialValues: any;

  poDefaultsForm = this.formBuilder.group({
    id: null,
    originCountry: null,
    pickupLocationCode: null,
    shipToLocationCode: null,
    pol: null,
    pod: null,
    incoterm: null,
    supplier: null,
    manufacturerOrgCode: null,
    consigneeOrgCode: null,
    transportMode: null,
  });

  bookingDefaultsForm = this.formBuilder.group({
    id: null,
    consigneeOrgCode: null,
    manufacturerOrgCode: null,
    shipperLocationCode: null,
    customerLocationCode: null,
    consigneeLocationCode: null,
    incoterm: null,
    pickupLocationCode: null,
    shipToLocationCode: null,
    pol: null,
    pod: null,
    forwarder: null,
    forwarderContactId: null,
  });

  supplierForm = new UntypedFormGroup({
    documentPermissions: this.formBuilder.array([]),
    bookAndCargoDatesDiff: new UntypedFormControl(0),
    containerFill: new UntypedFormControl(0),
    quantityShip: new UntypedFormControl(0),
    daysToBook: new UntypedFormControl(15),
    poDefaults: this.poDefaultsForm,
    bookingDefaults: this.bookingDefaultsForm,
    autoApprovedTransportMode: new UntypedFormControl(0),
    autoApprovedContainerizedType: new UntypedFormControl(0),
    canEditApprovedBooking: new UntypedFormControl(false),
    hasClearFreightForwarder: new UntypedFormControl(true)
  });

  networkForm = this.formBuilder.group({
    closePoUntilFulfilled: true,
    apiAccessKey: null,
  });
  public apiAccessKeyInfo: IApiAccessKey;
  public apiAccessKeyChanged: boolean = false;
  public errorRestrictionsArr: string[] = [];
  public errorRestrictionsArrToChild: string[] = [];
  public asnTriggerDefinition: IAsnTriggerDefinition;
  public asnTriggerChanged: boolean = false;
  public suppliersFilter = [];
  public defaultForwardContact = null;

  get documentPermissions() {
    return this.supplierForm.get('documentPermissions') as UntypedFormArray;
  }

  public loading: boolean = false;
  public loadingSupplierForm: boolean = false;

  public orgMode: PageOrgMode;
  public orgModes = PageOrgMode;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userNetworkService: UserNetworkApiService,
    private userService: UserService,
    private toastr: ToastrService,
    private contactPip: ContactPipe,
    private omCache: OmCacheService
  ) {}

  ngOnInit() {
    this.supplierFormInitialValues = this.supplierForm.value;
    this.networkFormInitialValues = this.networkForm.value;

    this.orgMode = this.userService.orgMode;

    this.documentTypes.forEach(docType => {
      this.documentPermissions.push(
        new UntypedFormGroup({ code: new UntypedFormControl(docType.code), enabled: new UntypedFormControl(false) })
      );
    });

    this.loading = true;
    forkJoin({
      organizations: this.userNetworkService.getOrgsInCurrentNetwork(),
      contacts: this.userNetworkService.getContactsInCurrentNetwork(),
    }).subscribe(
      ({ organizations, contacts }) => {
        this.manufacturers = organizations.manufacturers;
        this.consignees = organizations.consignees;
        this.suppliers = organizations.suppliers;
        this.forwarders = organizations.forwarders;
        this.selectedSupplier = this.suppliers[0];
        this.customer = organizations.customer;
        this.contacts = contacts.map((item: Contact) => {
          return {
            ...item,
            fullText: this.contactPip.transform(item, 'full')
          }
        });
        this.suppliersFilter = organizations.suppliers;

        this.initializeForms();
        this.loading = false;
        this.supplierForm.controls.hasClearFreightForwarder.valueChanges.subscribe(value=> {
          if(value) {
            // if value is true, add CLEARINC forwarder to the forwarder options
            const clearFreightForwarder = this.omCache.forwarderOrgs.find(item => item.code === 'CLEARINC')
            if(clearFreightForwarder && !this.forwarders.find(item => item.code === 'CLEARINC')) {
              this.forwarders.push(clearFreightForwarder)
            }
          } else {
            const clearFreightForwarderIndex = this.forwarders.findIndex(item => item.code === 'CLEARINC')
            if(clearFreightForwarderIndex > -1) {
              this.forwarders.splice(clearFreightForwarderIndex, 1)
              if((this.supplierForm.controls.bookingDefaults as UntypedFormGroup).controls.forwarder.value?.code === 'CLEARINC') {
                (this.supplierForm.controls.bookingDefaults as UntypedFormGroup).controls.forwarder.setValue(null);
              }
            }
          }
        })
      },
      _ => {
        this.toastr.error('Error retrieving required resources for business rules page.');
        this.loading = false;
      }
    );
  }

  save() {
    if (!this.supplierForm.dirty && !this.networkForm.dirty && !this.apiAccessKeyChanged && !this.asnTriggerChanged) {
      this.toastr.warning('There are no changes to be saved.');
      return;
    }

    if (this.apiAccessKeyChanged && this.apiAccessKeyInfo.apiIpRestrictions.length > 0) {
      let isInvalid = false;
      let isInvalidRange = false;
      this.errorRestrictionsArr = [];
      this.apiAccessKeyInfo.apiIpRestrictions.forEach((r, i) => {
        if (!Address4.isValid(r.startIp) || !Address4.isValid(r.endIp)) {
          isInvalid = true;
          if (!Address4.isValid(r.startIp)) {
            this.addErrorRestrictions('start-' + i);
          }
          if (!Address4.isValid(r.endIp)) {
            this.addErrorRestrictions('end-' + i);
          }
        } else {
          if (this.compareIPv4Addresses(r.startIp, r.endIp) > 0) {
            isInvalidRange = true;
            this.addErrorRestrictions('start-' + i);
            this.addErrorRestrictions('end-' + i);
          }
        }
      });

      this.errorRestrictionsArrToChild = this.errorRestrictionsArr;

      if (isInvalid) {
        this.toastr.warning('Please input the correct IP address.');
        return;
      }

      if (isInvalidRange) {
        this.toastr.warning('Please input the correct IP address range.');
        return;
      }
    }

    if (this.supplierForm.dirty) {
      this.saveSupplierForm();
    }

    if (this.networkForm.dirty || this.apiAccessKeyChanged || this.asnTriggerChanged) {
      this.saveNetworkForm();
    }
  }

  saveNetworkForm() {
    this.loading = true;
    const networkFormValue = this.networkForm.getRawValue();
    networkFormValue.apiAccessKey = this.apiAccessKeyInfo;
    networkFormValue.asnTriggerDefinition = this.asnTriggerDefinition;
    this.userNetworkService.saveNetworkBusinessRules(networkFormValue).subscribe(
      _ => {
        this.initializeForms();
        this.loading = false;
      },
      _ => {
        this.toastr.error('Error saving network business rules.');
        this.loading = false;
      }
    );
  }

  saveSupplierForm() {
    var supplierFormValue = this.supplierForm.getRawValue();

    if (supplierFormValue.bookingDefaults.forwarder && supplierFormValue.bookingDefaults.forwarder.code) {
      supplierFormValue.bookingDefaults.forwarderOrgCode = supplierFormValue.bookingDefaults.forwarder.code;
    } else {
      supplierFormValue.bookingDefaults.forwarderOrgCode = '';
    }

    const payload: ISupplierBusinessRuleSet = {
      quantityShip: supplierFormValue.quantityShip ?? 0,
      containerFill: supplierFormValue.containerFill ?? 0,
      bookAndCargoDatesDiff: supplierFormValue.bookAndCargoDatesDiff ?? 0,
      daysToBook: supplierFormValue.daysToBook ?? 0,
      documentsAllowed: supplierFormValue.documentPermissions.filter(d => d.enabled).map(d => d.code),
      poDefaults: supplierFormValue.poDefaults,
      bookingDefaults: supplierFormValue.bookingDefaults,
      autoApprovedTransportMode: supplierFormValue.autoApprovedTransportMode,
      autoApprovedContainerizedType: supplierFormValue.autoApprovedContainerizedType,
      canEditApprovedBooking: supplierFormValue.canEditApprovedBooking,
      hasClearFreightForwarder: supplierFormValue.hasClearFreightForwarder,
    };
    if (!this.selectedSupplier) {
      return;
    }
    this.loading = true;
    this.userNetworkService.saveBusinessRules(this.selectedSupplier.id, payload).subscribe(
      _ => {
        this.initializeForms();
        this.loading = false;
      },
      _ => {
        this.toastr.error('Error saving supplier business rules.');
        this.loading = false;
      }
    );
  }

  resetSupplierForm() {
    this.supplierForm.reset(this.supplierFormInitialValues);
    for (let i = 0; i < this.documentTypes.length; i++) {
      const controlGroup = this.documentPermissions.controls[i];
      controlGroup.get('code').setValue(this.documentTypes[i].code);
      controlGroup.get('enabled').setValue(false);
    }
    this.documentPermissions.markAsPristine();
    this.documentPermissions.markAsUntouched();
  }

  initializeForms() {
    this.userNetworkService.getNetworkBusinessRules().subscribe(res => {
      this.apiAccessKeyInfo = res.apiAccessKey;
      if (this.apiAccessKeyInfo.apiIpRestrictions.length < 1) {
        const restrictionsItem = {
          startIp: '',
          endIp: '',
          id: 0,
        };
        this.apiAccessKeyInfo.apiIpRestrictions = [...this.apiAccessKeyInfo.apiIpRestrictions, restrictionsItem];
      }
      this.asnTriggerDefinition = res.asnTriggerDefinition;
      this.networkForm.reset(this.networkFormInitialValues);
      this.networkForm.patchValue(res);
      this.networkForm.markAsPristine();
      this.supplierForm.markAsPristine()
    });

    this.loadingSupplierForm = true;
    if (!this.selectedSupplier) {
      this.resetSupplierForm();
      this.loadingSupplierForm = false;
      return;
    }
    this.userNetworkService.getBusinessRules(this.selectedSupplier.id).subscribe(
      res => {
        if (res) {
          this.resetSupplierForm();
          setTimeout(() => {
            this.supplierForm.patchValue(res);
            this.defaultForwardContact = res.bookingDefaults.forwarderContactId

            const forwarder = this.forwarders.find(f => f.code == res.bookingDefaults.forwarderOrgCode);
            if (forwarder) {
              setTimeout(() => {
                (this.supplierForm.controls.bookingDefaults as UntypedFormGroup).controls.forwarder.setValue(forwarder);
              }, 100)
            }

            this.documentPermissions.controls.forEach(control => {
              control.get('enabled').setValue(res.documentsAllowed.some(d => d == control.get('code').value));
              control.markAsPristine();
              control.markAsUntouched();
            });
            this.networkForm.markAsPristine();
            this.supplierForm.markAsPristine()
          }, 100)
        } else {
          this.resetSupplierForm();
        }
        this.loadingSupplierForm = false;
      },
      _ => {
        this.toastr.error('Error retrieving business rules.');
        this.loadingSupplierForm = false;
      }
    );
  }

  public refreshApiIpRestrictions(event: IApiAccessKey) {
    this.apiAccessKeyInfo = event;
    this.apiAccessKeyChanged = true;
  }

  public refreshAsnTrigger(event: IAsnTriggerDefinition) {
    this.asnTriggerDefinition = event;
    this.asnTriggerChanged = true;
  }

  compareIPv4Addresses(ip1: string, ip2: string): number {
    const pattern = /^(\d+)\.(\d+)\.(\d+)\.(\d+)$/;
    const match1 = pattern.exec(ip1);
    const match2 = pattern.exec(ip2);

    if (match1 && match2) {
      for (let i = 1; i <= 4; i++) {
        const part1 = parseInt(match1[i], 10);
        const part2 = parseInt(match2[i], 10);

        if (part1 < part2) {
          return -1;
        } else if (part1 > part2) {
          return 1;
        }
      }

      return 0;
    }
  }

  public addErrorRestrictions(text: string) {
    if (this.errorRestrictionsArr.indexOf(text) === -1) {
      this.errorRestrictionsArr.push(text);
    }
  }

  public removeErrorRestrictions(text: string) {
    const index = this.errorRestrictionsArr.indexOf(text);
    if (index !== -1) {
      this.errorRestrictionsArr.splice(index, 1);
    }
  }
}
