import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ListFilter } from '@models/filter';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { GetPurchaseOrders, PurchaseOrder, SavePurchaseOrder } from '../models/purchase-order.model';
import {GetBookings} from "@models/booking.model";

@Injectable()
export class PurchaseOrderService {
  constructor(private httpClient: HttpClient) {}
  public getLitePurchaseOrderById(id: number): Observable<PurchaseOrder> {
    return this.httpClient
      .get<PurchaseOrder>(`${environment.apiBaseURL}PurchaseOrder/lite/${id}`)
      .pipe(map(p => new PurchaseOrder(p)));
  }
  public getPurchaseOrderById(id: number): Observable<PurchaseOrder> {
    return this.httpClient
      .get<PurchaseOrder>(`${environment.apiBaseURL}PurchaseOrder/${id}`)
      .pipe(map(p => new PurchaseOrder(p)));
  }

  public getDefaultPo(): Observable<any> {
    return this.httpClient.get<any>(`${environment.apiBaseURL}PurchaseOrder/getPurchaseOrderDefaults`);
  }

  public getOpenPurchaseOrders(): Observable<PurchaseOrder[]> {
    // eslint-disable-next-line max-len
    return this.httpClient
      .get<PurchaseOrder[]>(`${environment.apiBaseURL}PurchaseOrder`)
      .pipe(map(po => po.map(p => new PurchaseOrder(p))));
  }

  public getPurchaseOrders(params: ListFilter): Observable<GetPurchaseOrders> {
    return this.httpClient.post<GetPurchaseOrders>(`${environment.apiBaseURL}PurchaseOrder/getPurchaseOrders`, params);
  }

  public getBookingPurchaseOrders(bookingId: number): Observable<PurchaseOrder[]> {
    // eslint-disable-next-line max-len
    return this.httpClient
      .get<PurchaseOrder[]>(`${environment.apiBaseURL}PurchaseOrder/booking/${bookingId}`)
      .pipe(map(po => po.map(p => new PurchaseOrder(p))));
  }

  public getBookingAssignablePurchaseOrders(bookingId: number): Observable<PurchaseOrder[]> {
    // eslint-disable-next-line max-len
    return this.httpClient
      .get<PurchaseOrder[]>(`${environment.apiBaseURL}PurchaseOrder/booking/${bookingId}/assignable`)
      .pipe(map(po => po.map(p => new PurchaseOrder(p))));
  }

  public getBookingOrderLineSelectorData(
    bookingId: number
  ): Observable<{ purchaseOrders: PurchaseOrder[]; assignablePurchaseOrders: PurchaseOrder[] }> {
    if (bookingId) {
      return forkJoin({
        purchaseOrders: this.getBookingPurchaseOrders(bookingId),
        assignablePurchaseOrders: this.getBookingAssignablePurchaseOrders(bookingId),
      });
    } else {
      return this.getBookingAssignablePurchaseOrders(null).pipe(
        map(assignablePurchaseOrders => {
          return {
            purchaseOrders: [],
            assignablePurchaseOrders: assignablePurchaseOrders,
          };
        })
      );
    }
  }

  public getLitePurchaseOrders(params: ListFilter): Observable<GetPurchaseOrders> {
    return this.httpClient.post<GetPurchaseOrders>(
      `${environment.apiBaseURL}PurchaseOrder/lite/getPurchaseOrders`,
      params
    );
  }

  public createPurchaseOrder(notLite: number, purchaseOrder: SavePurchaseOrder): Observable<any> {
    return this.httpClient.post<any>(notLite ? `${environment.apiBaseURL}PurchaseOrder` : `${environment.apiBaseURL}PurchaseOrder/lite`, purchaseOrder);
  }

  public updatePurchaseOrder(notLite: number, purchaseOrder: SavePurchaseOrder): Observable<any> {
    return this.httpClient.post<any>(notLite ? `${environment.apiBaseURL}PurchaseOrder` : `${environment.apiBaseURL}PurchaseOrder/lite`, purchaseOrder);
  }

  public supplierUpdate(purchaseOrderId: number, purchaseOrder: PurchaseOrder, rejectRequest: any): Observable<any> {
    const payload = {
      purchaseOrder: purchaseOrder,
      rejectionDetails: rejectRequest,
    };
    return this.httpClient.post<any>(
      `${environment.apiBaseURL}PurchaseOrder/${purchaseOrderId}/supplierUpdate`,
      payload
    );
  }

  public consigneeUpdate(purchaseOrderId: number, purchaseOrder: SavePurchaseOrder): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.apiBaseURL}PurchaseOrder/${purchaseOrderId}/consigneeUpdate`,
      purchaseOrder
    );
  }

  public supplierApprove(purchaseOrderId: number): Observable<any> {
    return this.httpClient.post<any>(`${environment.apiBaseURL}PurchaseOrder/${purchaseOrderId}/supplierApprove`, {});
  }

  public consigneeApprove(purchaseOrderId: number): Observable<any> {
    return this.httpClient.post<any>(`${environment.apiBaseURL}PurchaseOrder/${purchaseOrderId}/consigneeApprove`, {});
  }

  public supplierReject(purchaseOrderId: number, rejectRequest: any): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.apiBaseURL}PurchaseOrder/${purchaseOrderId}/supplierReject`,
      rejectRequest
    );
  }

  public deletePurchaseOrder(purchaseOrderId: number): Observable<any> {
    return this.httpClient.delete<any>(`${environment.apiBaseURL}PurchaseOrder/${purchaseOrderId}`);
  }

  public getPurchaseOrderHistory(id: number): Observable<any[]> {
    return this.httpClient.get<any[]>(`${environment.apiBaseURL}history/Purchase-Order/${id}`);
  }

  public cancelPurchaseOrder(purchaseOrderId: number, comments: string): Observable<any> {
    let data = { comments: comments };
    return this.httpClient.post<any>(`${environment.apiBaseURL}PurchaseOrder/${purchaseOrderId}/cancel`, data);
  }

  public reactivatePurchaseOrder(purchaseOrderId: number, comments: string): Observable<any> {
    let data = { comments: comments };
    return this.httpClient.post<any>(`${environment.apiBaseURL}PurchaseOrder/${purchaseOrderId}/reactivate`, data);
  }

  public getPurchaseOrderStatusCount(keyWord: string): Observable<any[]> {
    return this.httpClient.get<any[]>(`${environment.apiBaseURL}PurchaseOrder/statusCount?keyWord=${keyWord}`);
  }

  public downloadPurchaseOrders(params: ListFilter): any {
    return this.httpClient.post(`${environment.apiBaseURL}PurchaseOrder/downloadPurchaseOrders/`, params, {
      responseType: 'arraybuffer',
      observe: 'response',
    });
  }

  public downloadPurchaseOrdersTemplate(): any {
    return this.httpClient.get(`${environment.apiBaseURL}PurchaseOrder/downloadPurchaseOrdersTemplate`, {
      responseType: 'arraybuffer',
      observe: 'response',
    });
  }

  public uploadPurchaseOrders(fileToUpload: File): any {
    const formData: FormData = new FormData();
    formData.append('fileKey', fileToUpload, fileToUpload.name);
    return this.httpClient.post(`${environment.apiBaseURL}PurchaseOrder/uploadPurchaseOrders/`, formData, {
      responseType: 'arraybuffer',
      observe: 'response',
    });
  }

  public markPurchaseOrderPriority(id: number, isPriority: boolean): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.apiBaseURL}PurchaseOrder/${id}/updatePriority?isPriority=${isPriority}`,
      {}
    );
  }
}
