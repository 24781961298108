import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-om-card',
  styleUrls: ['./om-card.component.scss'],
  template: `
    <div
      class="om-card mat-elevation-z2"
      [ngClass]="customClass ? customClass : ''"
      [ngStyle]="{ 'min-height': minHeight ? minHeight : 'auto', height: height ? height : 'auto' }"
    >
      <ng-content></ng-content>
    </div>
  `,
})
export class OMCardComponent {
  @Input() minHeight: string;
  @Input() height: string;
  @Input() customClass: string;
  constructor() {}
}
