import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { UserService } from "src/app/modules/auth/services/user.service";

@Component({
    selector:'oms-user-password',
    templateUrl: './password-creation.component.html',
    styleUrls:['./password-creation.component.scss']
})

export class UserPasswordComponent implements OnInit{
    public passwordForm: UntypedFormGroup;
    public passwordMismatch = false;
    public inviteToken: string;
    public loading: boolean = false;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private formBuilder: UntypedFormBuilder,
        private userService: UserService){}

    ngOnInit(){
        this.activatedRoute.params.subscribe((params: Params) => {
            this.inviteToken = params['inviteToken'];
        });

        this.passwordForm = this.formBuilder.group({
            password : [null,[this.passwordStrength,Validators.minLength(8)]],
            confirmPassword : [null]
        });

        this.passwordForm.get("confirmPassword").valueChanges
        .subscribe((value) => {
          this.passwordMismatch = this.passwordForm.get('password').value.localeCompare(this.passwordForm.get('confirmPassword').value);
        });

        this.passwordForm.get("password").valueChanges
        .subscribe((value) => {
          if (value == "") {
            this.passwordForm.patchValue({ConfirmPassword: ""});
        }});

    }

    passwordStrength(control: UntypedFormControl) {
        if (!control.value) return null;
        const hasNumber = /\d/.test(control.value);
        const hasUpper = /[A-Z]/.test(control.value);
        const hasLower = /[a-z]/.test(control.value);
        const hasSpecial = /[~`!#@$%\^&*+=\-\_\[\]\\';,/{}()|\\":<>\?]/.test(control.value);

        const errors = {}
        if (!hasNumber) { errors["hasNumber"] = true}
        if (!hasUpper) { errors["hasUpper"] = true}
        if (!hasLower) { errors["hasLower"] = true}
        if (!hasSpecial) { errors["hasSpecial"] = true}

        const valid = hasNumber && hasUpper && hasLower && hasSpecial;
        if (!valid) {
          return errors;
        }
        return null;
      }

      createPassword(){
        const payload = {
            password: this.passwordForm.get('password').value,
            inviteToken: this.inviteToken
        }
        this.loading = true
        this.userService.confirmAccount(payload).subscribe(_ => {
            this.loading = false;
            this.router.navigateByUrl('app');
        });
      }
}
