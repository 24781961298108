import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {IPreferenceSet} from '@common-lib/components/options-panel/state/filter-preferences.state';
import {takeUntil} from 'rxjs/operators';
import {Store} from '@ngxs/store';
import {ContainerFilterField, Field, Filter, SeaShipmentFilterField} from '@common-lib/models/filter.model';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import * as moment from 'moment/moment';
import {UpdateFilters} from '@common-lib/components/options-panel/state/filter-preferences.actions';

@Component({
  selector: 'app-table-header-tools',
  templateUrl: './table-header-tools.component.html',
  styleUrls: ['./table-header-tools.component.scss'],
})
export class TableHeaderToolsComponent implements OnInit, OnDestroy {
  public preferences$: Observable<IPreferenceSet>;
  public preferences: IPreferenceSet;
  public curFilter: Filter[] = [];
  public headerAttr: Field | undefined;
  public filterForm: UntypedFormGroup;
  public selectedCondition: any = null;
  public filters: Filter[];
  public filterCondition = [];
  public conditions = [];
  public pageType =  'container';
  @Input() public headerValue = '';
  public unsubscribe$ = new Subject<void>();
  constructor(
    private store: Store,
    private fb: UntypedFormBuilder
  ) {}

  public ngOnInit(): void {
    const path = window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]
    switch (path) {
      case 'containers':
        this.pageType =  'container';
        this.preferences$ = this.store.select(state => state.filterPreferences.container);
        break;
      case 'air-shipments':
        this.pageType =  'air';
        this.preferences$ = this.store.select(state => state.filterPreferences.air);
        break;
      case 'sea-shipments':
        this.pageType =  'sea';
        this.preferences$ = this.store.select(state => state.filterPreferences.sea);
        break;
      case 'roa-shipments':
        this.pageType =  'roa';
        this.preferences$ = this.store.select(state => state.filterPreferences.roa);
        break;
      case 'rai-shipments':
        this.pageType =  'rai';
        this.preferences$ = this.store.select(state => state.filterPreferences.rai);
        break;
      case 'cou-shipments':
        this.pageType =  'cou';
        this.preferences$ = this.store.select(state => state.filterPreferences.cou);
        break;
    }

    this.preferences$.pipe(takeUntil(this.unsubscribe$)).subscribe(preferences => {
      const fieldList = this.pageType !== 'container' ? new SeaShipmentFilterField().fieldList.filter((field) => field.dbColumn !== '') : new ContainerFilterField().fieldList.filter((field) => field.dbColumn !== '');
      this.preferences = preferences;
      const sortField = fieldList.filter(item => item.text === this.headerValue)[0];
      this.headerAttr = sortField;
      if (!this.headerAttr) { return; }
      this.filterCondition = sortField.conditions;
      this.conditions = sortField.conditions;
      this.filters = preferences.filters;
      this.curFilter = preferences.filters.filter((item: Filter) => item.dataField === sortField.dbColumn);
      this.filterForm = this.fb.group({
        dataField: sortField.dbColumn,
        condition: new UntypedFormControl({ value: null, disabled: false }, [Validators.required]),
        value: new UntypedFormControl({ value: null, disabled: true }, [Validators.required]),
        value2: new UntypedFormControl(null, [Validators.nullValidator]),
      });
    });
  }

  public handleClickButton(e: any): void {
    e.preventDefault();
    e.stopPropagation();
  }

  public removeFilter(item: Filter, index: number): void {
    this.curFilter.splice(index, 1);
    this.filters = this.filters.filter((f) => f.id !== item.id);
  }

  public conditionChanged(e: any): void {
    this.selectedCondition = e.value;
    this.filterForm.controls.value.reset();
    this.filterForm.controls.value2.reset();
    this.filterForm.controls.value.enable();
    this.filterForm.controls.value2.enable();
  }

  public applyFilter(): void {
    let value1Override;
    let value2Override;
    if (this.selectedCondition?.value === 'Days Range') {
      const dayRange = Number.parseInt(this.filterForm.controls.value2.value, 10);
      value2Override = moment().add(dayRange, 'days').format('YYYY-MM-DD');
      if (dayRange < 0) {
        value1Override = value2Override;
        value2Override = this.filterForm.controls.value.value;
      }
    }
    if (this.selectedCondition && (this.filterForm.controls.value.value || this.filterForm.controls.value2.value)) {
      const filter = new Filter({
        dataField: this.headerAttr?.dbColumn,
        condition: this.selectedCondition?.text,
        value: value1Override ? value1Override : this.filterForm.controls.value.value,
        value2: value2Override ? value2Override : this.filterForm.controls.value2.value,
        selected: true,
        id: this.filters.length > 0 ? this.filters[this.filters.length - 1]?.id + 1 : 0,
      });
      this.filters.push(filter);
    }
    this.store.dispatch(new UpdateFilters({ ref: this.preferences.ref, filters: this.filters }));
    this.cancelFilter();
  }

  public cancelFilter(): void {
    this.filterForm.reset();
    // this.filterForm.controls.value.reset();
    // this.filterForm.controls.value2.reset();
    // this.filterForm.controls.condition.reset();
    this.filterForm.controls.value.disable();
    this.filterForm.controls.value2.disable();
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
