// This model maps to the Domain.Remark

import { RemarkType } from "@services/remark-api.service";

// on the API.
export class Remark {
  public id: number;
  public userFirstName: string;
  public userLastName: string;
  public date: Date;
  public message: string;
  public utcDate?: string;
  public type: RemarkType;

  constructor(init?: Partial<Remark>) {
    if (init?.id) this.id = init.id;
    if (init?.userFirstName) this.userFirstName = init.userFirstName;
    if (init?.userLastName) this.userLastName = init.userLastName;
    if (init?.date) this.date = init.date;
    if (init?.message) this.message = init.message;
    if (init?.type) this.type = init.type; else this.type = RemarkType.None;
  }
}

export class RemarkTimer {
  public refreshSeconds: number;
  public disabled: boolean;

  constructor(init?: Partial<RemarkTimer>) {
    Object.assign(this, init);
  }
}
